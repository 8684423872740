/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState, useEffect, useMemo } from 'react';
import Aos from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import { useHistory } from 'react-router-dom';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import { settingEdit } from '../store/action/settingAction.js';
import {
  akomodationView,
  akomodationEdit,
  akomodationGallery,
} from '../store/action/akomodationAction.js';

// loader
import ScaleLoader from 'react-spinners/ScaleLoader';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// css
import '../assets/css/Home.css';

const Themes = props => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [img1, setImg1] = useState('');
  const [img2, setImg2] = useState('');
  const [img3, setImg3] = useState('');
  const [desc, setDesc] = useState('');
  const [url, setUrl] = useState('');
  const [hargaweekend, setHargaweekend] = useState('');
  const [hargabiasa, setHargabiasa] = useState('');
  const [hargalibur, setHargalibur] = useState('');
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  // const params = useParams();
  // console.log(params);
  let history = useHistory();
  let dispatch = useDispatch();

  const idDetail = localStorage.getItem('akomondationDetail');
  useEffect(() => {
    setLoading(true);
    const params = history.location.pathname;
    const paramsArray = params.split('/');
    let idparam = paramsArray[2];
    // const new_str = word.replace(/\s/g, ' ');
    // const slug = new_str.toLowerCase();
    // console.log(slug);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
    dispatch(akomodationView(3));
    dispatch(akomodationEdit(false, idparam));
    dispatch(settingEdit('1'));
  }, [dispatch, idDetail, history]);

  const dataakomodationEdit = useSelector(state => state.akomodationEdit);
  const { akomodations } = dataakomodationEdit;

  useEffect(() => {
    dispatch(akomodationGallery(akomodations.id));
  }, [dispatch, akomodations]);

  const dataakomodationGallery = useSelector(state => state.akomodationGallery);
  const { akomGallery } = dataakomodationGallery;

  const dataAkomodation = useSelector(state => state.akomodationView);
  const { akomodationsView } = dataAkomodation;

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  // console.log(akomGallery);
  useMemo(() => {
    if (akomodations) {
      setTitle(akomodations.title);
      setSubtitle(akomodations.subtitle);
      setImg1(akomodations.img1);
      setImg2(akomodations.img2);
      setImg3(akomodations.img3);
      setDesc(akomodations.desc);
      setUrl(akomodations.url);
      setHargaweekend(akomodations.harga_weekend);
      setHargabiasa(akomodations.harga_biasa);
      setHargalibur(akomodations.harga_libur);
    }
  }, [akomodations]);

  useEffect(() => {
    Aos.init();
  }, []);

  function btnAkomondation(slug, id) {
    history.push(slug);
    localStorage.removeItem('akomondationDetail');
    localStorage.setItem('akomondationDetail', id);
  }

  const aboutSection = useRef(null);
  const BtnAbout = props => {
    window.scrollTo({
      top: aboutSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const campsiteSection = useRef(null);
  const BtnCampsite = () => {
    window.scrollTo({
      top: campsiteSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const akomondationsSection = useRef(null);
  const BtnAkomondations = () => {
    window.scrollTo({
      top: akomondationsSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const packagesSection = useRef(null);
  const BtnPackages = () => {
    window.scrollTo({
      top: packagesSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const eventSection = useRef(null);
  const BtnEvent = () => {
    window.scrollTo({
      top: eventSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  // navbar
  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 30
        ? setStickyClass('navbar-active')
        : setStickyClass('relative');
    }
  };

  //btn link send whatsapp
  const bookNow = e => {
    window.open(
      'https://api.whatsapp.com/send?phone=' +
        settings.telp +
        '&text=Hallo batu Camp! Saya ingin booking%20',
      '_blank'
    );
  };

  return (
    <>
      {loading ? (
        <div className="loader-spin">
          <ScaleLoader color={'#03441A'} loading={loading} size={500} />
        </div>
      ) : (
        <div className="my-app">
          <div id="navbar" className={'navbar-active ' + stickyClass}>
            <div className="sec__navbar">
              <div className="container">
                <div className="topnavbar">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col">
                      <div className="topleft">
                        <div className="icall">
                          <a
                            href={'tel:+' + settings.telp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>
                              <i className="fas fa-phone-volume fa-fw"></i>
                            </span>
                          </a>
                        </div>
                        <div className="title">
                          <p>For </p>
                          <p>+{settings.telp}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col text-center">
                      <img src={settings.logo} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4 col text-right m-auto">
                      <div className="topright">
                        <span className="btnbar pl-4" onClick={showSidebar}>
                          <i className="fas fa-bars fa-fw"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <nav className="nav-bottom">
                  <div className="row wrap__content">
                    <div className="col-lg-2 col-12 order1">
                      <div className="menu__res">
                        <a href={settings.fb} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-facebook"></i>
                          </span>
                        </a>
                        <a href={settings.ig} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-instagram"></i>
                          </span>
                        </a>
                        <a href={settings.yt} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-youtube"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-10 col-12 order2">
                      <div className="wrap__menu just">
                        <ul>
                          <li className="active">
                            <a href="/site">Home</a>
                          </li>
                          <li>
                            <a href="/#aboutus">About Us</a>
                          </li>
                          <li>
                            <a href="/#campsite" onClick={BtnCampsite}>
                              3 Spot Campsite
                            </a>
                          </li>
                          <li>
                            <a href="/#akomodations" onClick={BtnAkomondations}>
                              Akomodations
                            </a>
                          </li>
                          <li>
                            <a href="/#packages" onClick={BtnPackages}>
                              Packages
                            </a>
                          </li>
                          <li>
                            <a href="/#event" onClick={BtnEvent}>
                              Event
                            </a>
                          </li>
                        </ul>
                        <div className="wrap__button" id="viewAbout">
                          <button
                            className="btn btn-custom btn-view"
                            onClick={() => bookNow()}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <nav className={sidebar ? 'side-nav slide-in' : 'side-nav'}>
            <div id="nav-icon" className="hamburger">
              <span className="bar" onClick={showSidebar}></span>
            </div>
            <ul className="side-nav-list">
              <li className="side-nav-item">
                <a className="side-nav-link" href="/site">
                  Home
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#aboutus"
                  onClick={BtnAbout}
                >
                  About Us
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#campsite"
                  onClick={BtnCampsite}
                >
                  3 Spot Campsite
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#akomodations"
                  onClick={BtnAkomondations}
                >
                  Akomodations
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#packages"
                  onClick={BtnPackages}
                >
                  Packages
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="/#event" onClick={BtnEvent}>
                  Event
                </a>
              </li>
              <li className="side-nav-item">
                <div className="sosmed">
                  <a href={settings.fb} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-facebook"></i>
                    </span>
                  </a>
                  <a href={settings.ig} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                  <a href={settings.yt} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-youtube"></i>
                    </span>
                  </a>
                </div>
              </li>
              <li className="side-nav-item">
                <div className="wrap__button">
                  <button
                    className="btn btn-custom btn-view"
                    onClick={() => bookNow()}
                  >
                    Book Now
                  </button>
                </div>
              </li>
            </ul>
          </nav>

          <div className="sec__detailhigh">
            <div className="row">
              <div className="col-lg-6 col-12 m-auto order2">
                <div className="wrap__title">
                  <h2 className="animate-fadeup">{title}</h2>
                  <div className="p__title animate-fadeup">
                    <p>{subtitle}</p>
                  </div>
                  {hargabiasa ? (
                    <div className="price animate-fadeRight">
                      <h4>Harga senin - jumaat : </h4>
                      <h5>{hargabiasa}</h5>
                    </div>
                  ) : (
                    ''
                  )}

                  {hargaweekend ? (
                    <div className="price animate-fadeLeft">
                      <h4>Harga Sabtu - Minggu : </h4>
                      <h5>{hargaweekend}</h5>
                    </div>
                  ) : (
                    ''
                  )}

                  {hargalibur ? (
                    <div className="price animate-fadeRight">
                      <h4>Harga hari libur : </h4>
                      <h5>{hargalibur}</h5>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="button pt-3 animate-fadeup">
                    <button
                      className="btn btn-custom btn-view"
                      onClick={() => bookNow()}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Swiper
                  modules={[EffectFade, Autoplay]}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  effect="fade"
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={'auto'}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="wrap__content">
                      <img
                        src={img1}
                        alt=""
                        className="img-fluid img__content"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="wrap__content">
                      <img
                        src={img2}
                        alt=""
                        className="img-fluid img__content"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="wrap__content">
                      <img
                        src={img3}
                        alt=""
                        className="img-fluid img__content"
                      />
                    </div>
                  </SwiperSlide>
                  {/* {slide_img.map((img, i) => {
                return (
                  
                );
              })} */}
                </Swiper>
              </div>
            </div>
          </div>

          <div className="sec__detailabout" ref={aboutSection}>
            <div className="wrap__content">
              <div className="row" data-aos="fade-right">
                <div className="col-lg-2 col-12 wrap__left">
                  <div className="title__text">
                    <h1>GALLERY</h1>
                  </div>
                </div>
                <div className="col-lg-10 col-12">
                  <div className="wrap__right">
                    <ul>
                      {akomGallery.map((item, i) => {
                        return (
                          <li key={i}>
                            <img src={item.img} alt="" loading="lazy" />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sec__detaildesc" ref={aboutSection}>
            <div className="wrap__content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="container">
                    <div className="wrap__card" data-aos="fade-up">
                      <div className="title__desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: desc,
                          }}
                        />
                        {/* <h4>Deskripsi Villa</h4>
                        <p>
                          Villa Input dengan memiliki halaman yang luas dan
                          memiliki lapangan yang bisa digunakan untuk acara
                          kantor, komunitas ataupun acara keluarga karna villa
                          ini dapat menampung 100 orang. Villa Input ini
                          memiliki pemandangan dengan pegunungan dan perbukitan
                          yang sangat indah.
                        </p>
                        <h4 className="pt-4">
                          Beberapa fasilitas yang ada di villa input :{' '}
                        </h4>
                        <p>Lokasi : Taman Safari Cibereum</p>
                        <p>Kapasitas : 20-100</p>
                        <p>Kamar tidur : 6</p>
                        <p>Kamar mandi : 4</p>
                        <h4 className="pt-4">Fasilitas :</h4>
                        <ol>
                          <li>Water heater.</li>
                          <li>WIFI</li>
                          <li>Karaoke</li>
                          <li>Kolam Renang</li>
                          <li>Private Pool</li>
                          <li>Penanak nasi</li>
                          <li>Halaman</li>
                          <li>Lapangan bola </li>
                          <li>Parkir</li>
                        </ol> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {url ? (
            <div className="sec__detailvideo" ref={aboutSection}>
              <div className="wrap__content">
                <div className="row" data-aos="fade-left">
                  <div className="col-lg-10 col-12 order2">
                    <div className="wrap__right">
                      <iframe
                        className="iframe"
                        src={url}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-lg-2 col-12 wrap__left">
                    <div className="title__text">
                      <h1>PREVIEW VIDEO</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="sec__reserve bg-grey" ref={akomondationsSection}>
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>OTHER AKOMODATIONS</h2>
              </div>
              {akomodationsView.map((item, i) => {
                const str = item.title;
                const new_str = str.replace(/\s+/g, '-');
                const slug = new_str.toLowerCase();
                return (
                  <div className="row wrap__content" key={i} data-aos="fade-up">
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img1}
                        className="img-fluid bg__content img1"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img2}
                        className="img-fluid bg__content"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <div className="title__content">
                        <img
                          src={item.img3}
                          className="img-fluid bg__content img3"
                          alt=""
                        />
                        <div className="title">
                          <h3>{item.title}</h3>
                          <p>{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0 wrap__action">
                      <div className="wrap__card">
                        <h4>{item.harga_biasa}</h4>
                        <h5>/Night</h5>
                        <button
                          className="btn btn-custom"
                          onClick={() => btnAkomondation(slug, item.id)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <footer>
            <p>{settings.footer}</p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Themes;
