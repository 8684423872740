import {
  AKOMODATION_LIST_FAIL,
  AKOMODATION_LIST_REQUEST,
  AKOMODATION_LIST_SUCCESS,
  AKOMODATION_SAVE_REQUEST,
  AKOMODATION_SAVE_SUCCESS,
  AKOMODATION_SAVE_VALIDATION,
  AKOMODATION_SAVE_FAIL,
  AKOMODATION_DELETE_REQUEST,
  AKOMODATION_DELETE_SUCCESS,
  AKOMODATION_DELETE_FAIL,
  AKOMODATION_EDIT_REQUEST,
  AKOMODATION_EDIT_SUCCESS,
  AKOMODATION_EDIT_FAIL,
  AKOMODATION_SHOW_REQUEST,
  AKOMODATION_SHOW_SUCCESS,
  AKOMODATION_SHOW_FAIL,
  AKOMODATION_VIEW_REQUEST,
  AKOMODATION_VIEW_SUCCESS,
  AKOMODATION_VIEW_FAIL,
  AKOMODATION_GALLERY_REQUEST,
  AKOMODATION_GALLERY_SUCCESS,
  AKOMODATION_GALLERY_FAIL,
  AKOMODATION_UPDATE_VALIDATION,
  AKOMODATION_UPDATE_REQUEST,
  AKOMODATION_UPDATE_SUCCESS,
  AKOMODATION_UPDATE_FAIL,
} from '../contans/akomodationConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
import swal from 'sweetalert';
const initialState = {
  validation: [],
};

export const akomodationListReducer = (
  state = { listAkomodations: [], columns: [] },
  action
) => {
  switch (action.type) {
    case AKOMODATION_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case AKOMODATION_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        {
          label: 'title',
          field: 'title',
          width: 200,
        },
        {
          label: 'Image',
          field: 'urlImage',
          width: 200,
        },
        {
          label: 'Status',
          field: 'status',
          width: 200,
        },
        {
          label: 'Created By',
          field: 'created_by',
          width: 200,
        },
        {
          label: 'Updated By',
          field: 'updated_by',
          width: 200,
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },
      ];
      // let no = 0;
      for (let i = 0; i < data.length; i++) {
        // no++;
        var btn = (
          <BTNDatatable
            id={data[i].id}
            url={'akomodation/' + data[i].id}
            label="akomodation"
          />
        );
        var img = <img src={data[i].img1} alt="" height="30px" />;
        // var img = <img src={`http:://api/img/${data[i].img}`} alt="" />;
        data[i].action = btn;
        data[i].urlImage = img;
        // data[i].no = no;
      }
      return {
        ...state,
        loading: false,
        listAkomodations: data,
        columns: columns,
      };
    case AKOMODATION_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const akomodationCreateReducer = (
  state = { content: [], validation: [] },
  action
) => {
  switch (action.type) {
    case AKOMODATION_SAVE_REQUEST:
      return { ...state, loading: true, error: [] };
    case AKOMODATION_SAVE_SUCCESS:
      swal('Succes! Your data has been created!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, content: 'Insert success', loading: false, error: [] };
    case AKOMODATION_SAVE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case AKOMODATION_SAVE_FAIL:
      return {
        ...state,
        content: 'Insert failed',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const akomodationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case AKOMODATION_DELETE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [] };
    case AKOMODATION_DELETE_SUCCESS:
      swal('Poof! Your data has been deleted!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case AKOMODATION_DELETE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const akomodationEditReducer = (
  state = { akomodations: {}, clickShow: false },
  action
) => {
  switch (action.type) {
    case AKOMODATION_EDIT_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case AKOMODATION_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        akomodations: action.payload.data,
        clickShow: true,
      };
    case AKOMODATION_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const akomodationShowReducer = (
  state = { akomodationsShow: {}, clickShow: false },
  action
) => {
  switch (action.type) {
    case AKOMODATION_SHOW_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case AKOMODATION_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        akomodationsShow: action.payload.data,
        clickShow: true,
      };
    case AKOMODATION_SHOW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const akomodationViewReducer = (
  state = { akomodationsView: [], clickShow: false },
  action
) => {
  switch (action.type) {
    case AKOMODATION_VIEW_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case AKOMODATION_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        akomodationsView: action.payload.data,
        clickShow: true,
      };
    case AKOMODATION_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const akomodationGalleryReducer = (
  state = { akomGallery: [], clickShow: false },
  action
) => {
  switch (action.type) {
    case AKOMODATION_GALLERY_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case AKOMODATION_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        akomGallery: action.payload.data,
        clickShow: true,
      };
    case AKOMODATION_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const akomodationUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case AKOMODATION_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case AKOMODATION_UPDATE_SUCCESS:
      // message.success({ content: 'Update success', key });
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case AKOMODATION_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case AKOMODATION_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
