import { configureStore } from '@reduxjs/toolkit';
import {
  bannerListReducer,
  bannerCreateReducer,
  bannerDeleteReducer,
  bannerEditReducer,
  bannerUpdateReducer,
} from './reducer/bannerReducer';
import {
  sectionListReducer,
  sectionEditReducer,
} from './reducer/sectionReducer';
import {
  eventListReducer,
  eventCreateReducer,
  eventDeleteReducer,
  eventEditReducer,
  eventShowReducer,
  eventUpdateReducer,
} from './reducer/eventReducer';
import { settingEditReducer } from './reducer/settingReducer';

import {
  akomodationListReducer,
  akomodationCreateReducer,
  akomodationDeleteReducer,
  akomodationEditReducer,
  akomodationUpdateReducer,
  akomodationGalleryReducer,
  akomodationShowReducer,
  akomodationViewReducer,
} from './reducer/akomodationReducer';

import {
  packageListReducer,
  packageCreateReducer,
  packageDeleteReducer,
  packageEditReducer,
  packageUpdateReducer,
  packageGalleryReducer,
  packageShowReducer,
  packageViewReducer,
} from './reducer/packageReducer';

import {
  campsiteListReducer,
  campsiteCreateReducer,
  campsiteDeleteReducer,
  campsiteEditReducer,
  campsiteShowReducer,
  campsiteUpdateReducer,
  campsiteGalleryReducer,
} from './reducer/campsiteReducer';

import {
  userListReducer,
  userCreateReducer,
  userDeleteReducer,
  userEditReducer,
  userUpdateReducer,
} from './reducer/userReducer';

import {
  galleryListReducer,
  galleryCreateReducer,
  galleryDeleteReducer,
  galleryEditReducer,
  galleryUpdateReducer,
  limitGalleryReducer,
} from './reducer/galleryReducer';

export default configureStore({
  reducer: {
    bannerList: bannerListReducer,
    bannerSave: bannerCreateReducer,
    bannerEdit: bannerEditReducer,
    bannerDelete: bannerDeleteReducer,
    bannerUpdate: bannerUpdateReducer,

    sectionList: sectionListReducer,
    sectionEdit: sectionEditReducer,

    settingEdit: settingEditReducer,

    eventList: eventListReducer,
    eventSave: eventCreateReducer,
    eventEdit: eventEditReducer,
    eventDelete: eventDeleteReducer,
    eventUpdate: eventUpdateReducer,
    eventShow: eventShowReducer,

    akomodationList: akomodationListReducer,
    akomodationSave: akomodationCreateReducer,
    akomodationEdit: akomodationEditReducer,
    akomodationGallery: akomodationGalleryReducer,
    akomodationDelete: akomodationDeleteReducer,
    akomodationUpdate: akomodationUpdateReducer,
    akomodationView: akomodationViewReducer,
    akomodationShow: akomodationShowReducer,

    packageList: packageListReducer,
    packageSave: packageCreateReducer,
    packageEdit: packageEditReducer,
    packageGallery: packageGalleryReducer,
    packageDelete: packageDeleteReducer,
    packageUpdate: packageUpdateReducer,
    packageView: packageViewReducer,
    packageShow: packageShowReducer,

    campsiteList: campsiteListReducer,
    campsiteSave: campsiteCreateReducer,
    campsiteShow: campsiteShowReducer,
    campsiteEdit: campsiteEditReducer,
    campsiteGallery: campsiteGalleryReducer,
    campsiteDelete: campsiteDeleteReducer,
    campsiteUpdate: campsiteUpdateReducer,

    userList: userListReducer,
    userSave: userCreateReducer,
    userEdit: userEditReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,

    galleryList: galleryListReducer,
    gallerySave: galleryCreateReducer,
    galleryEdit: galleryEditReducer,
    limitGallery: limitGalleryReducer,
    galleryDelete: galleryDeleteReducer,
    galleryUpdate: galleryUpdateReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
});
