import {
  EVENT_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_SAVE_REQUEST,
  EVENT_SAVE_SUCCESS,
  EVENT_SAVE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_EDIT_REQUEST,
  EVENT_EDIT_SUCCESS,
  EVENT_EDIT_FAIL,
  EVENT_SHOW_REQUEST,
  EVENT_SHOW_SUCCESS,
  EVENT_SHOW_FAIL,
  EVENT_UPDATE_VALIDATION,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
} from '../contans/eventConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
import swal from 'sweetalert';
const initialState = {
  validation: [],
};

export const eventListReducer = (
  state = { listEvents: [], columns: [] },
  action
) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case EVENT_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        // {
        //   label: 'id',
        //   field: 'no',
        //   width: 150,
        //   sort: 'disabled',
        //   attributes: {
        //     'aria-controls': 'DataTable',
        //     'aria-label': 'Name',
        //   },
        // },
        {
          label: 'image',
          field: 'urlImage',
          width: 270,
        },
        {
          label: 'title',
          field: 'title',
          width: 200,
        },
        {
          label: 'sub title',
          field: 'subtitle',
          width: 100,
        },
        {
          label: 'status',
          field: 'status',
          sort: 'disabled',
          width: 150,
        },
        {
          label: 'created by',
          field: 'created_by',
          width: 150,
        },
        {
          label: 'updated by',
          field: 'updated_by',
          width: 150,
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },
      ];
      // let no = 0;
      for (let i = 0; i < data.length; i++) {
        // no++;
        var btn = (
          <BTNDatatable
            id={data[i].id}
            url={'events/' + data[i].id}
            label="event"
          />
        );
        var img = <img src={data[i].img} alt="" height="30px" />;
        // var img = <img src={`http:://api/img/${data[i].img}`} alt="" />;
        data[i].action = btn;
        data[i].urlImage = img;
        // data[i].no = no;
      }
      return {
        ...state,
        loading: false,
        listEvents: data,
        columns: columns,
      };
    case EVENT_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const eventCreateReducer = (state = { content: [] }, action) => {
  switch (action.type) {
    case EVENT_SAVE_REQUEST:
      return { ...state, loading: true, error: [] };
    case EVENT_SAVE_SUCCESS:
      swal('Succes! Your data has been created!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, content: 'Insert success', loading: false, error: [] };
    case EVENT_SAVE_FAIL:
      return {
        ...state,
        content: 'Insert failed',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const eventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [] };
    case EVENT_DELETE_SUCCESS:
      swal('Poof! Your data has been deleted!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case EVENT_DELETE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const eventEditReducer = (
  state = { events: {}, clickShow: false },
  action
) => {
  switch (action.type) {
    case EVENT_EDIT_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case EVENT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.data,
        clickShow: true,
      };
    case EVENT_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const eventShowReducer = (state = { events: {} }, action) => {
  switch (action.type) {
    case EVENT_SHOW_REQUEST:
      return { ...state, loading: true, error: [] };
    case EVENT_SHOW_SUCCESS:
      return { ...state, loading: false, events: action.payload.data };
    case EVENT_SHOW_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const eventUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case EVENT_UPDATE_SUCCESS:
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case EVENT_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case EVENT_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
