import {
  CAMPSITE_LIST_FAIL,
  CAMPSITE_LIST_REQUEST,
  CAMPSITE_LIST_SUCCESS,
  CAMPSITE_SAVE_FAIL,
  CAMPSITE_SAVE_REQUEST,
  CAMPSITE_SAVE_SUCCESS,
  CAMPSITE_SAVE_VALIDATION,
  CAMPSITE_DELETE_FAIL,
  CAMPSITE_DELETE_REQUEST,
  CAMPSITE_DELETE_SUCCESS,
  CAMPSITE_EDIT_FAIL,
  CAMPSITE_EDIT_REQUEST,
  CAMPSITE_EDIT_SUCCESS,
  CAMPSITE_SHOW_FAIL,
  CAMPSITE_SHOW_REQUEST,
  CAMPSITE_SHOW_SUCCESS,
  CAMPSITE_GALLERY_FAIL,
  CAMPSITE_GALLERY_REQUEST,
  CAMPSITE_GALLERY_SUCCESS,
  CAMPSITE_UPDATE_FAIL,
  CAMPSITE_UPDATE_REQUEST,
  CAMPSITE_UPDATE_SUCCESS,
  CAMPSITE_UPDATE_VALIDATION,
} from '../contans/campsiteConst';
import axios from 'axios';

export const getcampsiteList = status => async dispatch => {
  try {
    dispatch({
      type: CAMPSITE_LIST_REQUEST,
    });

    if (status) {
      const { data } = await axios.get(`campsites/status/${status}`, {});

      dispatch({
        type: CAMPSITE_LIST_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`campsites`, {});

      dispatch({
        type: CAMPSITE_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: CAMPSITE_LIST_FAIL,
      payload: err,
    });
  }
};

export const campsiteSave =
  (title, subtitle, image, desc, url, status) => async dispatch => {
    try {
      dispatch({
        type: CAMPSITE_SAVE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img', image);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('status', status);

      await axios.post(`campsites`, formData, config);
      dispatch({
        type: CAMPSITE_SAVE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: CAMPSITE_SAVE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: CAMPSITE_SAVE_FAIL,
          payload: err,
        });
      }
    }
  };

export const campsiteDelete = id => async dispatch => {
  try {
    dispatch({
      type: CAMPSITE_DELETE_REQUEST,
    });

    await axios.delete(`campsites/${id}`);

    dispatch({
      type: CAMPSITE_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: CAMPSITE_DELETE_FAIL,
      payload: err,
    });
  }
};

export const campsiteEdit = (id, slug) => async dispatch => {
  try {
    dispatch({
      type: CAMPSITE_EDIT_REQUEST,
    });

    if (slug) {
      const { data } = await axios.get(`campsites/slug/${slug}`);
      dispatch({
        type: CAMPSITE_EDIT_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`campsites/${id}`);
      dispatch({
        type: CAMPSITE_EDIT_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: CAMPSITE_EDIT_FAIL,
      payload: err,
    });
  }
};

export const campsiteShow = id => async dispatch => {
  try {
    dispatch({
      type: CAMPSITE_SHOW_REQUEST,
    });

    const { data } = await axios.get(`campsites/${id}`);
    dispatch({
      type: CAMPSITE_SHOW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: CAMPSITE_SHOW_FAIL,
      payload: err,
    });
  }
};

export const campsiteGallery = id => async dispatch => {
  try {
    dispatch({
      type: CAMPSITE_GALLERY_REQUEST,
    });

    const { data } = await axios.get(`gallery/Campsite/${id}`);
    dispatch({
      type: CAMPSITE_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: CAMPSITE_GALLERY_FAIL,
      payload: err,
    });
  }
};

export const campsiteUpdate =
  (id, title, subtitle, image, desc, url, status) => async dispatch => {
    try {
      dispatch({
        type: CAMPSITE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img', image);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`campsites/${id}`, formData, config);

      dispatch({
        type: CAMPSITE_UPDATE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: CAMPSITE_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: CAMPSITE_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
