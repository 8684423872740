/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';
// core components
import Datatable from 'components/Datatable/DatatableBasic';
import swal from 'sweetalert';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import {
  getbannerList,
  bannerSave,
  bannerUpdate,
} from '../../store/action/bannerAction.js';

const Banner = () => {
  // set variables
  const [image, setImage] = useState('');
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [titlehigh, setTitlehigh] = useState('');
  const [ordering, setOrdering] = useState('1');
  const [labelbtn, setLabelbtn] = useState('Simpan');
  const [loading, setLoading] = useState(false);
  const [showShow, setShowShow] = useState(false);
  // set let
  let dispatch = useDispatch();

  // sintac edit
  const id = localStorage.getItem('bannerEditID'); //get id from session button edit
  useEffect(() => {
    localStorage.removeItem('bannerEditID');
    dispatch(getbannerList());
    setOrdering('1');
    if (id) {
      setLabelbtn('Update');
    }
  }, [id, dispatch]);

  // get list banner
  const dataBanner = useSelector(state => state.bannerList);
  const { listBanners, columns } = dataBanner;

  // banner edit
  const databannerEdit = useSelector(state => state.bannerEdit);
  const { banners, clickShow } = databannerEdit;

  // isset edit
  useMemo(() => {
    if (banners) {
      setImage(banners.img);
      setTitle(banners.title);
      setSubtitle(banners.subtitle);
      setTitlehigh(banners.titlehigh);
      setOrdering(banners.ordering);
      setShowShow(clickShow);

      if (banners.status === 'Inactive') {
        setStatus('1');
      } else {
        setStatus('0');
      }
    }
  }, [banners, clickShow]);

  // sintac create
  const handleChange = e => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (!ordering) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else {
      if (banners.id) {
        dispatch(
          bannerUpdate(
            banners.id,
            title,
            subtitle,
            titlehigh,
            ordering,
            image,
            status
          )
        );
      } else {
        dispatch(
          bannerSave(image, title, subtitle, titlehigh, ordering, status)
        );
      }
    }
  };

  // set collapse
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      {loading ? (
        <div id="myDiv">
          <div className="h5 m-t-xs text-white loading-text">
            <span className="loading">permintaan sedang diproses.....</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> New
                          Banner
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow}>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={title}
                                  id="input-title"
                                  name="title"
                                  placeholder="masukan title"
                                  onChange={e => {
                                    setTitle(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-titlehigh"
                                >
                                  Mid Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={titlehigh}
                                  id="input-titlehigh"
                                  name="titlehigh"
                                  placeholder="masukan sub title"
                                  onChange={e => {
                                    setTitlehigh(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-subtitle"
                                >
                                  Sub Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={subtitle}
                                  id="input-subtitle"
                                  name="subtitle"
                                  placeholder="masukan sub title"
                                  onChange={e => {
                                    setSubtitle(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-ordering"
                                >
                                  Ordering
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="number"
                                  id="input-ordering"
                                  name="ordering"
                                  defaultValue={ordering ? ordering : '1'}
                                  onChange={e => {
                                    setOrdering(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image"
                                >
                                  Image
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image}
                                  id="input-image"
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Status
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="status"
                                  value={status}
                                  onChange={e => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="0">Active</option>
                                  <option value="1">Inactive</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit(e)}
                                >
                                  <i className="fa fa-plus"></i> {labelbtn}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Banner List</h3>
              </CardHeader>
              <Datatable rows={listBanners} columns={columns} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Banner;
