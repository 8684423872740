/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';

import Axios from 'axios';

import React, { useState, useEffect } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';

// get data api
import { useDispatch } from 'react-redux';

import { sectionUpdate } from '../../store/action/sectionAction.js';

const Banner = () => {
  // set let
  let dispatch = useDispatch();

  const [aboutT, setAboutT] = useState('');
  const [aboutST, setAboutST] = useState('');
  const [campsiteT, setCampsiteT] = useState('');
  const [campsiteST, setCampsiteST] = useState('');
  const [reserveT, setReserveT] = useState('');
  const [reserveST, setReserveST] = useState('');
  const [packageT, setPackageT] = useState('');
  const [packageST, setPackageST] = useState('');
  const [galleryT, setGalleryT] = useState('');
  const [galleryST, setGalleryST] = useState('');
  const [aboutbT, setAboutbT] = useState('');
  const [aboutbST, setAboutbST] = useState('');
  const [mapsT, setMapsT] = useState('');
  const [mapsST, setMapsST] = useState('');
  useEffect(() => {
    Axios.get(`sectionmenus`).then(response => {
      setAboutT(response.data.data['0'].title);
      setAboutST(response.data.data['0'].subtitle);
      setCampsiteT(response.data.data['1'].title);
      setCampsiteST(response.data.data['1'].subtitle);
      setReserveT(response.data.data['2'].title);
      setReserveST(response.data.data['2'].subtitle);
      setPackageT(response.data.data['3'].title);
      setPackageST(response.data.data['3'].subtitle);
      setGalleryT(response.data.data['4'].title);
      setGalleryST(response.data.data['4'].subtitle);
      setAboutbT(response.data.data['5'].title);
      setAboutbST(response.data.data['5'].subtitle);
      setMapsT(response.data.data['6'].title);
      setMapsST(response.data.data['6'].subtitle);
    });
  }, []);

  const handleSubmit = () => {
    if (
      !aboutT ||
      !aboutST ||
      !campsiteT ||
      !campsiteST ||
      !reserveT ||
      !reserveST ||
      !packageT ||
      !packageST ||
      !galleryT ||
      !galleryST ||
      !aboutbT ||
      !aboutbST ||
      !mapsT ||
      !mapsST
    ) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else {
      dispatch(
        sectionUpdate(
          aboutT,
          aboutST,
          campsiteT,
          campsiteST,
          reserveT,
          reserveST,
          packageT,
          packageST,
          galleryT,
          galleryST,
          aboutbT,
          aboutbST,
          mapsT,
          mapsST
        )
      );

      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  };

  // set collapse
  const [showShow, setShowShow] = useState(true);
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> Section
                          title
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow}>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          Section About
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Title About
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={aboutT}
                                  id="input-Tabout"
                                  name="Tabout"
                                  placeholder="masukan Tabout"
                                  onChange={e => {
                                    setAboutT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title About
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={aboutST}
                                  id="input-stAbout"
                                  name="stAbout"
                                  rows="4"
                                  placeholder="masukan stAbout"
                                  onChange={e => {
                                    setAboutST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section Campsite
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Title Campsite
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={campsiteT}
                                  id="input-campsiteT"
                                  name="campsiteT"
                                  placeholder="masukan campsite Title"
                                  onChange={e => {
                                    setCampsiteT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title About
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={campsiteST}
                                  id="input-campsiteST"
                                  name="campsiteST"
                                  rows="4"
                                  placeholder="masukan sub title campsite"
                                  onChange={e => {
                                    setCampsiteST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section Akomodation
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-akomodation"
                                >
                                  Title Akomodation
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={reserveT}
                                  id="input-reserveT"
                                  name="reserveT"
                                  placeholder="masukan title akomodation"
                                  onChange={e => {
                                    setReserveT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title Akomodation
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={reserveST}
                                  id="input-reserveST"
                                  name="reserveST"
                                  rows="4"
                                  placeholder="masukan sub title Akomodation"
                                  onChange={e => {
                                    setReserveST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section Package
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-package"
                                >
                                  Title Package
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={packageT}
                                  id="input-packageT"
                                  name="packageT"
                                  placeholder="masukan title package"
                                  onChange={e => {
                                    setPackageT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title Package
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={packageST}
                                  id="input-packageST"
                                  name="packageST"
                                  rows="4"
                                  placeholder="masukan sub title Package"
                                  onChange={e => {
                                    setPackageST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section Gallery
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-gallery"
                                >
                                  Title Gallery
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={galleryT}
                                  id="input-galleryT"
                                  name="galleryT"
                                  placeholder="masukan title gallery"
                                  onChange={e => {
                                    setGalleryT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title Gallery
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={galleryST}
                                  id="input-galleryST"
                                  name="galleryST"
                                  rows="4"
                                  placeholder="masukan sub title gallery"
                                  onChange={e => {
                                    setGalleryST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section About Bottom
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-aboutb"
                                >
                                  Title About Bottom
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={aboutbT}
                                  id="input-aboutbT"
                                  name="aboutbT"
                                  placeholder="masukan title aboutb"
                                  onChange={e => {
                                    setAboutbT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-aboutb"
                                >
                                  Sub Title About Bottom
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={aboutbST}
                                  id="input-aboutbST"
                                  name="aboutbST"
                                  rows="4"
                                  placeholder="masukan sub title aboutb"
                                  onChange={e => {
                                    setAboutbST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <h6 className="heading-small text-muted mb-4 mt-4">
                          Section Maps
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-maps"
                                >
                                  Title Maps
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={mapsT}
                                  id="input-mapsT"
                                  name="mapsT"
                                  placeholder="masukan title maps"
                                  onChange={e => {
                                    setMapsT(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Sub Title Maps
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="textarea"
                                  defaultValue={mapsST}
                                  id="input-mapsST"
                                  name="mapsST"
                                  rows="4"
                                  placeholder="masukan sub title maps"
                                  onChange={e => {
                                    setMapsST(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit()}
                                >
                                  <i className="fa fa-plus"></i> Update
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
