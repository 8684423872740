import {
  GALLERY_LIST_FAIL,
  GALLERY_LIST_REQUEST,
  GALLERY_LIST_SUCCESS,
  GALLERY_SAVE_REQUEST,
  GALLERY_SAVE_SUCCESS,
  GALLERY_SAVE_VALIDATION,
  GALLERY_SAVE_FAIL,
  GALLERY_DELETE_REQUEST,
  GALLERY_DELETE_SUCCESS,
  GALLERY_DELETE_FAIL,
  GALLERY_EDIT_REQUEST,
  GALLERY_EDIT_SUCCESS,
  GALLERY_EDIT_FAIL,
  GALLERY_LIMIT_REQUEST,
  GALLERY_LIMIT_SUCCESS,
  GALLERY_LIMIT_FAIL,
  GALLERY_UPDATE_VALIDATION,
  GALLERY_UPDATE_REQUEST,
  GALLERY_UPDATE_SUCCESS,
  GALLERY_UPDATE_FAIL,
} from '../contans/galleryConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
import swal from 'sweetalert';
const initialState = {
  validation: [],
};

export const galleryListReducer = (
  state = { listgallerys: [], columns: [] },
  action
) => {
  switch (action.type) {
    case GALLERY_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case GALLERY_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        {
          label: 'Kategori',
          field: 'kategori',
          width: 200,
        },
        {
          label: 'Title kategori',
          field: 'title_kategori',
          width: 200,
        },
        {
          label: 'image',
          field: 'urlImage',
          width: 200,
        },
        {
          label: 'ordering',
          field: 'ordering',
          width: 200,
        },
        {
          label: 'status',
          field: 'status',
          sort: 'disabled',
          width: 150,
        },
        {
          label: 'created by',
          field: 'created_by',
          width: 150,
        },
        {
          label: 'updated by',
          field: 'updated_by',
          width: 150,
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },
      ];
      // let no = 0;
      for (let i = 0; i < data.length; i++) {
        // no++;
        var btn = (
          <BTNDatatable
            id={data[i].id}
            url={'gallerys/' + data[i].id}
            label="gallery"
          />
        );
        var img = <img src={data[i].img} alt="" height="30px" />;
        // var img = <img src={`http:://api/img/${data[i].img}`} alt="" />;
        data[i].action = btn;
        data[i].urlImage = img;
        // data[i].no = no;
      }
      return {
        ...state,
        loading: false,
        listgallerys: data,
        columns: columns,
      };
    case GALLERY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const galleryCreateReducer = (
  state = { content: [], validation: [] },
  action
) => {
  switch (action.type) {
    case GALLERY_SAVE_REQUEST:
      return { ...state, loading: true, error: [] };
    case GALLERY_SAVE_SUCCESS:
      swal('Succes! Your data has been created!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, content: 'Insert success', loading: false, error: [] };
    case GALLERY_SAVE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case GALLERY_SAVE_FAIL:
      return {
        ...state,
        content: 'Insert failed',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const galleryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GALLERY_DELETE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [] };
    case GALLERY_DELETE_SUCCESS:
      swal('Poof! Your data has been deleted!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case GALLERY_DELETE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const galleryEditReducer = (
  state = { gallerys: {}, clickShow: false },
  action
) => {
  switch (action.type) {
    case GALLERY_EDIT_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case GALLERY_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        gallerys: action.payload.data,
        clickShow: true,
      };
    case GALLERY_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const limitGalleryReducer = (
  state = { limitsGallery: [], clickShow: false },
  action
) => {
  switch (action.type) {
    case GALLERY_LIMIT_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case GALLERY_LIMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        limitsGallery: action.payload.data,
        clickShow: true,
      };
    case GALLERY_LIMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};

export const galleryUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GALLERY_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case GALLERY_UPDATE_SUCCESS:
      // message.success({ content: 'Update success', key });
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case GALLERY_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case GALLERY_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
