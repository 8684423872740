/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';
// core components
import Datatable from 'components/Datatable/DatatableBasic';
import swal from 'sweetalert';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import {
  geteventList,
  eventSave,
  eventUpdate,
} from '../../store/action/eventAction.js';

const Event = () => {
  // set let
  let dispatch = useDispatch();

  // set variables
  const [image, setImage] = useState('');
  const [status, setStatus] = useState('0');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [labelbtn, setLabelbtn] = useState('Simpan');
  const [showShow, setShowShow] = useState(false);
  const [loading, setLoading] = useState(false);

  // sintac edit
  const editID = localStorage.getItem('eventID'); //get id from session button edit
  useEffect(() => {
    localStorage.removeItem('eventID');
    setStatus('0');
    if (editID) {
      setLabelbtn('Update');
    }
    dispatch(geteventList);
  }, [dispatch, editID]);

  // get list event
  const dataEvent = useSelector(state => state.eventList);
  const { listEvents, columns } = dataEvent;

  // event edit
  const dataeventEdit = useSelector(state => state.eventEdit);
  const { events, clickShow } = dataeventEdit;

  // isset edit
  useMemo(() => {
    if (events) {
      setImage(events.img);
      setTitle(events.title);
      setSubtitle(events.subtitle);
      setShowShow(clickShow);

      if (events.status === 'Inactive') {
        setStatus('1');
      } else {
        setStatus('0');
      }
    }
  }, [events, clickShow]);

  // sintac create
  const handleChange = e => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = () => {
    // e.preventDefault();
    setLoading(true);

    if (!title || !subtitle) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else {
      if (events.id) {
        dispatch(eventUpdate(events.id, image, title, subtitle, status));
      } else {
        dispatch(eventSave(image, title, subtitle, status));
      }
    }
  };

  // set collapse
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      {loading ? (
        <div id="myDiv">
          <div className="h5 m-t-xs text-white loading-text">
            <span className="loading">permintaan sedang diproses.....</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> New
                          Event
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow}>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={title}
                                  id="input-title"
                                  name="title"
                                  placeholder="masukan title"
                                  onChange={e => {
                                    setTitle(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-subtitle"
                                >
                                  Sub Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={subtitle}
                                  id="input-subtitle"
                                  name="subtitle"
                                  placeholder="masukan sub title"
                                  onChange={e => {
                                    setSubtitle(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Status
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="status"
                                  value={status}
                                  // defaultValue={status}
                                  onChange={e => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="0">Active</option>
                                  <option value="1">Inactive</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image"
                                >
                                  Image
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image}
                                  id="input-image"
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit()}
                                >
                                  <i className="fa fa-plus"></i> {labelbtn}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Event List</h3>
              </CardHeader>
              <Datatable rows={listEvents} columns={columns} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Event;
