export const GALLERY_LIST_REQUEST = 'GALLERY_LIST_REQUEST';
export const GALLERY_LIST_SUCCESS = 'GALLERY_LIST_SUCCESS';
export const GALLERY_LIST_FAIL = 'GALLERY_LIST_FAIL';

export const GALLERY_SAVE_REQUEST = 'GALLERY_SAVE_REQUEST';
export const GALLERY_SAVE_SUCCESS = 'GALLERY_SAVE_SUCCESS';
export const GALLERY_SAVE_FAIL = 'GALLERY_SAVE_FAIL';
export const GALLERY_SAVE_VALIDATION = 'GALLERY_SAVE_VALIDATION';

export const GALLERY_EDIT_REQUEST = 'GALLERY_EDIT_REQUEST';
export const GALLERY_EDIT_SUCCESS = 'GALLERY_EDIT_SUCCESS';
export const GALLERY_EDIT_FAIL = 'GALLERY_EDIT_FAIL';

export const GALLERY_LIMIT_REQUEST = 'GALLERY_LIMIT_REQUEST';
export const GALLERY_LIMIT_SUCCESS = 'GALLERY_LIMIT_SUCCESS';
export const GALLERY_LIMIT_FAIL = 'GALLERY_GALLERY_FAIL';

export const GALLERY_UPDATE_REQUEST = 'GALLERY_UPDATE_REQUEST';
export const GALLERY_UPDATE_SUCCESS = 'GALLERY_UPDATE_SUCCESS';
export const GALLERY_UPDATE_FAIL = 'GALLERY_UPDATE_FAIL';
export const GALLERY_UPDATE_VALIDATION = 'GALLERY_UPDATE_VALIDATION';

export const GALLERY_DELETE_REQUEST = 'GALLERY_DELETE_REQUEST';
export const GALLERY_DELETE_SUCCESS = 'GALLERY_DELETE_SUCCESS';
export const GALLERY_DELETE_FAIL = 'GALLERY_DELETE_FAIL';
