import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_SAVE_FAIL,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_VALIDATION,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_VALIDATION,
} from '../contans/userConst';
import axios from 'axios';

export const getuserList = async dispatch => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const { data } = await axios.get(`users`, {});

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: USER_LIST_FAIL,
      payload: err,
    });
  }
};

export const userSave = (name, email, password) => async dispatch => {
  try {
    dispatch({
      type: USER_SAVE_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);

    await axios.post(`users`, formData, config);
    dispatch({
      type: USER_SAVE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: USER_SAVE_VALIDATION,
        payload: e.response.data.errors,
      });
    } else {
      const err =
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message;
      dispatch({
        type: USER_SAVE_FAIL,
        payload: err,
      });
    }
  }
};

export const userDelete = id => async dispatch => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    await axios.delete(`users/${id}`);

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: USER_DELETE_FAIL,
      payload: err,
    });
  }
};

export const userEdit = id => async dispatch => {
  try {
    dispatch({
      type: USER_EDIT_REQUEST,
    });

    const { data } = await axios.get(`users/${id}`);
    dispatch({
      type: USER_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: USER_EDIT_FAIL,
      payload: err,
    });
  }
};

export const userUpdate = (id, name, email, password) => async dispatch => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('_method', 'PUT');

    await axios.post(`users/${id}`, formData, config);

    dispatch({
      type: USER_UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: USER_UPDATE_VALIDATION,
        payload: e.response.data.errors,
      });
    } else {
      const err =
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message;
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: err,
      });
    }
  }
};
