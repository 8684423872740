/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';
// core components
import Datatable from 'components/Datatable/DatatableBasic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// get data api
import { useDispatch, useSelector } from 'react-redux';

import {
  getpackageList,
  packageSave,
  packageUpdate,
} from '../../store/action/packageAction.js';

import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Package = () => {
  // set variables
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [status, setStatus] = useState('0');
  const [url, setUrl] = useState('');
  const [desc, setDesc] = useState('');
  const [hargabiasa, setHargabiasa] = useState('');
  const [hargaweekend, setHargaweekend] = useState('');
  const [hargalibur, setHargalibur] = useState('');
  const [labelbtn, setLabelbtn] = useState('Simpan');
  const [loading, setLoading] = useState(false);
  const [showShow, setShowShow] = useState(false);
  // set let
  let dispatch = useDispatch();

  //   let inistyle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // inistyle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  // sintac edit
  const viewID = localStorage.getItem('packageViewID');
  const editID = localStorage.getItem('packageEditID');
  useEffect(() => {
    localStorage.removeItem('packageViewID');
    localStorage.removeItem('packageEditID');
    dispatch(getpackageList());
    if (viewID) {
      setIsOpen(true);
    }

    if (editID) {
      setLabelbtn('Update');
    }
    setStatus(0);
  }, [viewID, editID, dispatch]);

  // get list
  const datapackage = useSelector(state => state.packageList);
  const { listPackages, columns } = datapackage;

  // edit
  const dataEdit = useSelector(state => state.packageEdit);
  const { packages, clickShow } = dataEdit;

  // view
  const dataShow = useSelector(state => state.packageShow);
  const { packagesShow } = dataShow;

  //   view gallery
  const datapackageGallery = useSelector(state => state.packageGallery);
  const { packagesGallery } = datapackageGallery;

  // isset edit
  useMemo(() => {
    if (packages) {
      setTitle(packages.title);
      setSubtitle(packages.subtitle);
      setImage1(packages.img1);
      setImage2(packages.img2);
      setImage3(packages.img3);
      setStatus(packages.status);
      setUrl(packages.url);
      setDesc(packages.desc);
      setHargabiasa(packages.harga_biasa);
      setHargaweekend(packages.harga_weekend);
      setHargalibur(packages.harga_libur);
      setShowShow(clickShow);

      if (packages.status === 'Inactive') {
        setStatus('1');
      } else if (packages.status === 'Active') {
        setStatus('0');
      }
    }
  }, [packages, clickShow]);

  // sintac create
  const handleChange1 = e => {
    setImage1(e.target.files[0]);
  };
  const handleChange2 = e => {
    setImage2(e.target.files[0]);
  };
  const handleChange3 = e => {
    setImage3(e.target.files[0]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (!title || !subtitle) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else {
      if (packages.id) {
        dispatch(
          packageUpdate(
            packages.id,
            title,
            subtitle,
            image1,
            image2,
            image3,
            desc,
            url,
            hargabiasa,
            hargaweekend,
            hargalibur,
            status
          )
        );
      } else {
        dispatch(
          packageSave(
            title,
            subtitle,
            image1,
            image2,
            image3,
            desc,
            url,
            hargabiasa,
            hargaweekend,
            hargalibur,
            status
          )
        );
      }
    }
  };

  // set collapse
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      {loading ? (
        <div id="myDiv">
          <div className="h5 m-t-xs text-white loading-text">
            <span className="loading">permintaan sedang diproses.....</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="card__header">
            <div className="row">
              <div className="col">Package Detail</div>
              <div className="col">
                <span className="cursor-pointer" onClick={closeModal}>
                  <i className="ni ni-fat-remove text-danger float-right">
                    close
                  </i>
                </span>
              </div>
            </div>
          </div>
          <div className="border-bottom"></div>
          <div className="card__body">
            <div className="row">
              <div className="col">
                <h2 className="mb-0">{packagesShow.title}</h2>
                <p>{packagesShow.subtitle}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-4">
                <img
                  src={packagesShow.img1}
                  alt=""
                  className="img-fluid imgdetail"
                />
              </div>
              <div className="col-lg-3 col-4">
                <img
                  src={packagesShow.img2}
                  alt=""
                  className="img-fluid imgdetail"
                />
              </div>
              <div className="col-lg-3 col-4">
                <img
                  src={packagesShow.img3}
                  alt=""
                  className="img-fluid imgdetail"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="price">
                  <h4 className="mb-0 pt-4">Harga Senin - Jumat</h4>
                  <p>
                    {packagesShow.harga_biasa ? packagesShow.harga_biasa : '-'}
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="price">
                  <h4 className="mb-0">Harga Sabtu - Minggu</h4>
                  <p>
                    {packagesShow.harga_weekend
                      ? packagesShow.harga_weekend
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="price">
                  <h4 className="mb-0">Harga Libur</h4>
                  <p>
                    {packagesShow.harga_libur ? packagesShow.harga_libur : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="content-iframe">
                  <h4>Video Youtube</h4>
                  <a
                    href={packagesShow.url}
                    target="_blank"
                    className="url"
                    rel="noreferrer"
                  >
                    {packagesShow.url}
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="gallery">
                  <h4 className="mb-4 pt-4">Gallery</h4>
                  <ul>
                    {packagesGallery.map((item, i) => {
                      return (
                        <li key={i}>
                          <img src={item.img} alt="" loading="lazy" />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="desc__title">
                  <h4 className="pt-4">Deskripsi :</h4>
                  {/* <p>{packagesShow.desc}</p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: packagesShow.desc,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <h2 ref={_inistyle => (inistyle = _inistyle)}>Hello</h2> */}
        </Modal>
      </div>
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> New
                          Package
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow} className="show-height">
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        {/* {validation.length !== 0 ? (
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">
                              {validation['img']}
                            </li>
                          </ul>
                        ) : null} */}
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Title
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={title}
                                  id="input-title"
                                  name="title"
                                  placeholder="masukan title"
                                  onChange={e => {
                                    setTitle(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-url"
                                >
                                  Url video Youtube
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={url}
                                  id="input-url"
                                  name="url"
                                  placeholder="masukan link embed youtube"
                                  onChange={e => {
                                    setUrl(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Status
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="status"
                                  value={status}
                                  defaultValue={status}
                                  onChange={e => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="0">Active</option>
                                  <option value="1">Inactive</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-subtitle"
                                >
                                  Sub Title
                                </label>
                                <textarea
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultalue={subtitle}
                                  value={subtitle}
                                  id="input-subtitle"
                                  name="subtitle"
                                  placeholder="masukan sub title"
                                  onChange={e => {
                                    setSubtitle(e.target.value);
                                  }}
                                >
                                  {subtitle}
                                </textarea>
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image1"
                                >
                                  Image 1
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image1}
                                  id="input-image1"
                                  onChange={handleChange1}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image2"
                                >
                                  Image 2
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image2}
                                  id="input-image2"
                                  onChange={handleChange2}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image3"
                                >
                                  Image 3
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image3}
                                  id="input-image3"
                                  onChange={handleChange3}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-hargabiasa"
                                >
                                  Harga Senin - Jumat
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={hargabiasa}
                                  id="input-hargabiasa"
                                  name="hargabiasa"
                                  placeholder="masukan harga senin - jumaat"
                                  onChange={e => {
                                    setHargabiasa(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-hargaweekend"
                                >
                                  Harga Sabtu - Minggu
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={hargaweekend}
                                  id="input-hargaweekend"
                                  name="hargaweekend"
                                  placeholder="masukan harga sabtu - minggu"
                                  onChange={e => {
                                    setHargaweekend(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-hargalibur"
                                >
                                  Harga Hari Libur
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={hargalibur}
                                  id="input-hargalibur"
                                  name="hargalibur"
                                  placeholder="masukan harga libur"
                                  onChange={e => {
                                    setHargalibur(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Deskripsi
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={
                                  desc ? desc : '<p>Tulis deskripsi disini!</p>'
                                }
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                  // console.log(
                                  //   'Editor is ready to use!',
                                  //   editor
                                  // );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  // console.log({ event, editor, data });
                                  setDesc(data);
                                }}
                                onBlur={(event, editor) => {
                                  // console.log('Blur.', editor);
                                  const data = editor.getData();
                                  setDesc(data);
                                }}
                                onFocus={(event, editor) => {
                                  const data = editor.getData();
                                  setDesc(data);
                                  // console.log('Focus.', editor);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit(e)}
                                >
                                  <i className="fa fa-plus"></i> {labelbtn}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Package List </h3>
              </CardHeader>
              <Datatable rows={listPackages} columns={columns} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Package;
