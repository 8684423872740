/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';
// core components
import Datatable from 'components/Datatable/DatatableBasic';

// get data api
import { useDispatch, useSelector } from 'react-redux';

import {
  getgalleryList,
  gallerySave,
  galleryUpdate,
} from '../../store/action/galleryAction.js';

import { getakomodationList } from '../../store/action/akomodationAction.js';
import { getpackageList } from '../../store/action/packageAction.js';
import { getcampsiteList } from '../../store/action/campsiteAction.js';

const Gallery = () => {
  // set variables
  const [kategori, setKategori] = useState('');
  const [titlekategori, setTitlekategori] = useState('');
  const [idkategori, setIdkategori] = useState('');
  const [image, setImage] = useState('');
  const [status, setStatus] = useState('0');
  const [ordering, setOrdering] = useState('');
  const [labelbtn, setLabelbtn] = useState('Simpan');
  const [loading, setLoading] = useState(false);
  const [showShow, setShowShow] = useState(false);
  // set let
  let dispatch = useDispatch();

  // sintac edit
  const editID = localStorage.getItem('galleryEditID');
  useEffect(() => {
    localStorage.removeItem('galleryEditID');
    dispatch(getgalleryList);

    if (editID) {
      setLabelbtn('Update');
    }
    setStatus(0);
    setOrdering(1);
  }, [editID, dispatch]);

  // get list
  const datagallery = useSelector(state => state.galleryList);
  const { listgallerys, columns } = datagallery;

  // edit
  const dataEdit = useSelector(state => state.galleryEdit);
  const { gallerys, clickShow } = dataEdit;

  // isset edit
  useMemo(() => {
    if (gallerys) {
      setKategori(gallerys.kategori);
      setTitlekategori(gallerys.title_kategori);
      setIdkategori(gallerys.idkategori);
      setImage(gallerys.img);
      setStatus(gallerys.status);
      setOrdering(gallerys.ordering);
      setShowShow(clickShow);

      if (gallerys.status === 'Inactive') {
        setStatus('1');
      } else if (gallerys.status === 'Active') {
        setStatus('0');
      }
    }
  }, [gallerys, clickShow]);

  // sintac create
  const handleChange = e => {
    setImage(e.target.files[0]);
  };

  const dataakomodation = useSelector(state => state.akomodationList);
  const { listAkomodations } = dataakomodation;

  const datacampsite = useSelector(state => state.campsiteList);
  const { listcampsites } = datacampsite;

  const datapackage = useSelector(state => state.packageList);
  const { listPackages } = datapackage;

  useEffect(() => {
    if (editID) {
      if (kategori === 'Akomodation') {
        dispatch(getakomodationList());
      } else if (kategori === 'Package') {
        console.log('oke');
        dispatch(getpackageList());
      } else if (kategori === 'Campsite') {
        dispatch(getcampsiteList());
      } else {
        setTitlekategori('all');
        setIdkategori('1');
      }
    }
  }, [editID, kategori, dispatch]);

  const handleTitlekategori = e => {
    const idname = e.target
      .querySelectorAll('option')
      [e.target.selectedIndex].getAttribute('idname');

    setTitlekategori(idname);
    setIdkategori(e.target.value);
  };

  const handleKategori = e => {
    setKategori(e.target.value);

    if (e.target.value === 'Akomodation') {
      dispatch(getakomodationList());
    } else if (e.target.value === 'Package') {
      dispatch(getpackageList());
    } else if (e.target.value === 'Campsite') {
      dispatch(getcampsiteList());
    } else {
      setTitlekategori('all');
      setIdkategori('1');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    if (!kategori || !idkategori || !titlekategori) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else {
      if (gallerys.id) {
        dispatch(
          galleryUpdate(
            gallerys.id,
            kategori,
            idkategori,
            titlekategori,
            image,
            ordering,
            status
          )
        );
      } else {
        dispatch(
          gallerySave(
            kategori,
            idkategori,
            titlekategori,
            image,
            ordering,
            status
          )
        );
      }
    }
  };

  // set collapse
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      {loading ? (
        <div id="myDiv">
          <div className="h5 m-t-xs text-white loading-text">
            <span className="loading">permintaan sedang diproses.....</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> New
                          Gallery
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow}>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="5">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Kategori
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="status"
                                  value={kategori}
                                  onChange={e => handleKategori(e)}
                                >
                                  <option selected disabled>
                                    pilih kategori
                                  </option>
                                  <option value="All">All</option>
                                  <option value="Akomodation">
                                    Akomodation
                                  </option>
                                  <option value="Package">Package</option>
                                  <option value="Campsite">Campsite</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Title Kategori
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="title_kategori"
                                  value={idkategori}
                                  onChange={e => handleTitlekategori(e)}
                                >
                                  <option selected disabled>
                                    pilih title kategori
                                  </option>
                                  {kategori === 'All' ? (
                                    <option value="all">All</option>
                                  ) : (
                                    ''
                                  )}
                                  {kategori === 'Akomodation'
                                    ? listAkomodations.map(item => {
                                        return (
                                          <>
                                            <option
                                              key={item}
                                              value={item.id}
                                              idname={item.title}
                                            >
                                              {item.title}
                                            </option>
                                          </>
                                        );
                                      })
                                    : ''}
                                  {kategori === 'Campsite'
                                    ? listcampsites.map(item => {
                                        return (
                                          <>
                                            <option
                                              key={item}
                                              value={item.id}
                                              idname={item.title}
                                            >
                                              {item.title}
                                            </option>
                                          </>
                                        );
                                      })
                                    : ''}
                                  {kategori === 'Package'
                                    ? listPackages.map(item => {
                                        return (
                                          <>
                                            <option
                                              key={item}
                                              value={item.id}
                                              idname={item.title}
                                            >
                                              {item.title}
                                            </option>
                                          </>
                                        );
                                      })
                                    : ''}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-ordering"
                                >
                                  Ordering
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="number"
                                  id="input-ordering"
                                  name="ordering"
                                  defaultValue={ordering ? ordering : '1'}
                                  onChange={e => {
                                    setOrdering(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Status
                                </label>
                                <select
                                  className="form-control-alternative form-control"
                                  name="status"
                                  value={status}
                                  onChange={e => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="0">Active</option>
                                  <option value="1">Inactive</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image"
                                >
                                  Image
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={image}
                                  id="input-image"
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit(e)}
                                >
                                  <i className="fa fa-plus"></i> {labelbtn}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Gallery List </h3>
              </CardHeader>
              <Datatable rows={listgallerys} columns={columns} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Gallery;
