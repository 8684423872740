export const CAMPSITE_LIST_REQUEST = 'CAMPSITE_LIST_REQUEST';
export const CAMPSITE_LIST_SUCCESS = 'CAMPSITE_LIST_SUCCESS';
export const CAMPSITE_LIST_FAIL = 'CAMPSITE_LIST_FAIL';

export const CAMPSITE_SAVE_REQUEST = 'CAMPSITE_SAVE_REQUEST';
export const CAMPSITE_SAVE_SUCCESS = 'CAMPSITE_SAVE_SUCCESS';
export const CAMPSITE_SAVE_FAIL = 'CAMPSITE_SAVE_FAIL';
export const CAMPSITE_SAVE_VALIDATION = 'CAMPSITE_SAVE_VALIDATION';

export const CAMPSITE_EDIT_REQUEST = 'CAMPSITE_EDIT_REQUEST';
export const CAMPSITE_EDIT_SUCCESS = 'CAMPSITE_EDIT_SUCCESS';
export const CAMPSITE_EDIT_FAIL = 'CAMPSITE_EDIT_FAIL';

export const CAMPSITE_SHOW_REQUEST = 'CAMPSITE_SHOW_REQUEST';
export const CAMPSITE_SHOW_SUCCESS = 'CAMPSITE_SHOW_SUCCESS';
export const CAMPSITE_SHOW_FAIL = 'CAMPSITE_SHOW_FAIL';

export const CAMPSITE_GALLERY_REQUEST = 'CAMPSITE_GALLERY_REQUEST';
export const CAMPSITE_GALLERY_SUCCESS = 'CAMPSITE_GALLERY_SUCCESS';
export const CAMPSITE_GALLERY_FAIL = 'CAMPSITE_GALLERY_FAIL';

export const CAMPSITE_UPDATE_REQUEST = 'CAMPSITE_UPDATE_REQUEST';
export const CAMPSITE_UPDATE_SUCCESS = 'CAMPSITE_UPDATE_SUCCESS';
export const CAMPSITE_UPDATE_FAIL = 'CAMPSITE_UPDATE_FAIL';
export const CAMPSITE_UPDATE_VALIDATION = 'CAMPSITE_UPDATE_VALIDATION';

export const CAMPSITE_DELETE_REQUEST = 'CAMPSITE_DELETE_REQUEST';
export const CAMPSITE_DELETE_SUCCESS = 'CAMPSITE_DELETE_SUCCESS';
export const CAMPSITE_DELETE_FAIL = 'CAMPSITE_DELETE_FAIL';
