import {
  PACKAGE_LIST_FAIL,
  PACKAGE_LIST_REQUEST,
  PACKAGE_LIST_SUCCESS,
  PACKAGE_SAVE_FAIL,
  PACKAGE_SAVE_REQUEST,
  PACKAGE_SAVE_SUCCESS,
  PACKAGE_SAVE_VALIDATION,
  PACKAGE_DELETE_FAIL,
  PACKAGE_DELETE_REQUEST,
  PACKAGE_DELETE_SUCCESS,
  PACKAGE_EDIT_FAIL,
  PACKAGE_EDIT_REQUEST,
  PACKAGE_EDIT_SUCCESS,
  PACKAGE_SHOW_FAIL,
  PACKAGE_SHOW_REQUEST,
  PACKAGE_SHOW_SUCCESS,
  PACKAGE_VIEW_FAIL,
  PACKAGE_VIEW_REQUEST,
  PACKAGE_VIEW_SUCCESS,
  PACKAGE_GALLERY_FAIL,
  PACKAGE_GALLERY_REQUEST,
  PACKAGE_GALLERY_SUCCESS,
  PACKAGE_UPDATE_FAIL,
  PACKAGE_UPDATE_REQUEST,
  PACKAGE_UPDATE_SUCCESS,
  PACKAGE_UPDATE_VALIDATION,
} from '../contans/packageConst';
import axios from 'axios';

export const getpackageList = status => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_LIST_REQUEST,
    });

    if (status) {
      const { data } = await axios.get(`packages/status/${status}`, {});

      dispatch({
        type: PACKAGE_LIST_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`packages`, {
        //   params: {
        //     page: page,
        //     search: search,
        //   },
      });

      dispatch({
        type: PACKAGE_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_LIST_FAIL,
      payload: err,
    });
  }
};

export const packageSave =
  (
    title,
    subtitle,
    image1,
    image2,
    image3,
    desc,
    url,
    hargabiasa,
    hargaweekend,
    hargalibur,
    status
  ) =>
  async dispatch => {
    try {
      dispatch({
        type: PACKAGE_SAVE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img1', image1);
      formData.append('img2', image2);
      formData.append('img3', image3);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('harga_biasa', hargabiasa);
      formData.append('harga_weekend', hargaweekend);
      formData.append('harga_libur', hargalibur);
      formData.append('status', status);

      await axios.post(`packages`, formData, config);
      dispatch({
        type: PACKAGE_SAVE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: PACKAGE_SAVE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: PACKAGE_SAVE_FAIL,
          payload: err,
        });
      }
    }
  };

export const packageDelete = id => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_DELETE_REQUEST,
    });

    await axios.delete(`packages/${id}`);

    dispatch({
      type: PACKAGE_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_DELETE_FAIL,
      payload: err,
    });
  }
};

export const packageEdit = (id, slug) => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_EDIT_REQUEST,
    });

    if (slug) {
      const { data } = await axios.get(`packages/slug/${slug}`);
      dispatch({
        type: PACKAGE_EDIT_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`packages/${id}`);
      dispatch({
        type: PACKAGE_EDIT_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_EDIT_FAIL,
      payload: err,
    });
  }
};

export const packageGallery = id => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_GALLERY_REQUEST,
    });

    const { data } = await axios.get(`gallery/Package/${id}`);
    dispatch({
      type: PACKAGE_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_GALLERY_FAIL,
      payload: err,
    });
  }
};

export const packageUpdate =
  (
    id,
    title,
    subtitle,
    image1,
    image2,
    image3,
    desc,
    url,
    hargabiasa,
    hargaweekend,
    hargalibur,
    status
  ) =>
  async dispatch => {
    try {
      dispatch({
        type: PACKAGE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img1', image1);
      formData.append('img2', image2);
      formData.append('img3', image3);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('harga_biasa', hargabiasa);
      formData.append('harga_weekend', hargaweekend);
      formData.append('harga_libur', hargalibur);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`packages/${id}`, formData, config);

      dispatch({
        type: PACKAGE_UPDATE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: PACKAGE_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: PACKAGE_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };

export const packageView = id => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_VIEW_REQUEST,
    });

    const { data } = await axios.get(`packages/limit/${id}`);
    dispatch({
      type: PACKAGE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_VIEW_FAIL,
      payload: err,
    });
  }
};

export const packageShow = id => async dispatch => {
  try {
    dispatch({
      type: PACKAGE_SHOW_REQUEST,
    });

    const { data } = await axios.get(`packages/${id}`);
    dispatch({
      type: PACKAGE_SHOW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: PACKAGE_SHOW_FAIL,
      payload: err,
    });
  }
};
