import {
  BANNER_LIST_FAIL,
  BANNER_LIST_REQUEST,
  BANNER_LIST_SUCCESS,
  BANNER_SAVE_FAIL,
  BANNER_SAVE_REQUEST,
  BANNER_SAVE_SUCCESS,
  BANNER_SAVE_VALIDATION,
  BANNER_DELETE_FAIL,
  BANNER_DELETE_REQUEST,
  BANNER_DELETE_SUCCESS,
  BANNER_EDIT_FAIL,
  BANNER_EDIT_REQUEST,
  BANNER_EDIT_SUCCESS,
  BANNER_UPDATE_FAIL,
  BANNER_UPDATE_REQUEST,
  BANNER_UPDATE_SUCCESS,
  BANNER_UPDATE_VALIDATION,
} from '../contans/bannerConst';
import axios from 'axios';

export const getbannerList = status => async dispatch => {
  // export const bannerList = (page, search) => async dispatch => {
  try {
    dispatch({
      type: BANNER_LIST_REQUEST,
    });

    if (status) {
      const { data } = await axios.get(`banners/status/${status}`, {});

      dispatch({
        type: BANNER_LIST_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`banners`, {});

      dispatch({
        type: BANNER_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: BANNER_LIST_FAIL,
      payload: err,
    });
  }
};

export const bannerSave =
  (file, title, subtitle, titlehigh, ordering, status) => async dispatch => {
    try {
      dispatch({
        type: BANNER_SAVE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('img', file);
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('titlehigh', titlehigh);
      formData.append('status', status);
      formData.append('ordering', ordering);

      await axios.post(`banners`, formData, config);
      dispatch({
        type: BANNER_SAVE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: BANNER_SAVE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: BANNER_SAVE_FAIL,
          payload: err,
        });
      }
    }
  };

export const bannerDelete = id => async dispatch => {
  try {
    dispatch({
      type: BANNER_DELETE_REQUEST,
    });

    await axios.delete(`banners/${id}`);

    dispatch({
      type: BANNER_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: BANNER_DELETE_FAIL,
      payload: err,
    });
  }
};

export const bannerEdit = id => async dispatch => {
  try {
    dispatch({
      type: BANNER_EDIT_REQUEST,
    });

    const { data } = await axios.get(`banners/${id}`);
    dispatch({
      type: BANNER_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: BANNER_EDIT_FAIL,
      payload: err,
    });
  }
};

export const bannerUpdate =
  (id, title, subtitle, titlehigh, ordering, image, status) =>
  async dispatch => {
    try {
      dispatch({
        type: BANNER_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('titlehigh', titlehigh);
      formData.append('ordering', ordering);
      formData.append('img', image);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`banners/${id}`, formData, config);

      dispatch({
        type: BANNER_UPDATE_SUCCESS,
      });
      // history.push('/BANNER-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: BANNER_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: BANNER_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
