/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import Axios from 'axios';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import store from './store/index';
import { Provider } from 'react-redux';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import ThemesLayout from 'layouts/Themes.js';
import DetailLayout from 'layouts/Detail.js';
import PackageLayout from 'layouts/Package.js';
import CampsiteLayout from 'layouts/Campsite.js';
import GalleryLayout from 'layouts/Gallery.js';

Axios.defaults.baseURL = 'https://api.curugbatugedecamp.com/api';
// Axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

const vLogin = localStorage.getItem('userInfo');

if (vLogin !== null || vLogin !== 'undefined') {
  Axios.defaults.headers.common.Authorization = `Bearer ${vLogin}`;
} else {
  Axios.defaults.headers.common.Authorization = null;
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/site">
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/" exact render={props => <ThemesLayout {...props} />} />
        <Route
          path="/site"
          exact
          render={props => <ThemesLayout {...props} />}
        />
        <Route path="/akomondation/:id" component={DetailLayout} />
        <Route path="/campsites/:id" component={CampsiteLayout} />
        <Route path="/packages/:id" component={PackageLayout} />
        <Route path="/gallery/" component={GalleryLayout} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
