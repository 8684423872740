/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import Aos from 'aos';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import { settingEdit } from '../store/action/settingAction.js';
import { getgalleryList } from '../store/action/galleryAction.js';

// loader
import ScaleLoader from 'react-spinners/ScaleLoader';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// css
import '../assets/css/Home.css';

const Themes = props => {
  const [galleryT, setGalleryT] = useState('');
  const [galleryST, setGalleryST] = useState('');

  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  let dispatch = useDispatch();

  const idDetail = localStorage.getItem('campsiteDetail');
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    dispatch(getgalleryList);
    dispatch(settingEdit('1'));
    Axios.get(`sectionmenus`).then(response => {
      setGalleryT(response.data.data['4'].title);
      setGalleryST(response.data.data['4'].subtitle);
    });
  }, [dispatch, idDetail]);

  const datagallery = useSelector(state => state.galleryList);
  const { listgallerys } = datagallery;

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  const aboutSection = useRef(null);
  const BtnAbout = props => {
    window.scrollTo({
      top: aboutSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const campsiteSection = useRef(null);
  const BtnCampsite = () => {
    window.scrollTo({
      top: campsiteSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const akomondationsSection = useRef(null);
  const BtnAkomondations = () => {
    window.scrollTo({
      top: akomondationsSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const packagesSection = useRef(null);
  const BtnPackages = () => {
    window.scrollTo({
      top: packagesSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const eventSection = useRef(null);
  const BtnEvent = () => {
    window.scrollTo({
      top: eventSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  // navbar
  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 30
        ? setStickyClass('navbar-active')
        : setStickyClass('relative');
    }
  };

  //btn link send whatsapp
  const bookNow = e => {
    window.open(
      'https://api.whatsapp.com/send?phone=' +
        settings.telp +
        '&text=Hallo batu Camp! Saya ingin booking%20',
      '_blank'
    );
  };

  return (
    <>
      {loading ? (
        <div className="loader-spin">
          <ScaleLoader color={'#03441A'} loading={loading} size={500} />
        </div>
      ) : (
        <div className="my-app">
          <div id="navbar" className={'navbar-active ' + stickyClass}>
            <div className="sec__navbar">
              <div className="container">
                <div className="topnavbar">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col">
                      <div className="topleft">
                        <div className="icall">
                          <a
                            href={'tel:+' + settings.telp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>
                              <i className="fas fa-phone-volume fa-fw"></i>
                            </span>
                          </a>
                        </div>
                        <div className="title">
                          <p>For </p>
                          <p>+{settings.telp}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col text-center">
                      <img src={settings.logo} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4 col text-right m-auto">
                      <div className="topright">
                        <span className="btnbar pl-4" onClick={showSidebar}>
                          <i className="fas fa-bars fa-fw"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <nav className="nav-bottom">
                  <div className="row wrap__content">
                    <div className="col-lg-2 col-12 order1">
                      <div className="menu__res">
                        <a href={settings.fb} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-facebook"></i>
                          </span>
                        </a>
                        <a href={settings.ig} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-instagram"></i>
                          </span>
                        </a>
                        <a href={settings.yt} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-youtube"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-10 col-12 order2">
                      <div className="wrap__menu just">
                        <ul>
                          <li className="active">
                            <a href="/site">Home</a>
                          </li>
                          <li>
                            <a href="/#aboutus" onClick={BtnAbout}>
                              About Us
                            </a>
                          </li>
                          <li>
                            <a href="/#campsite" onClick={BtnCampsite}>
                              3 Spot Campsite
                            </a>
                          </li>
                          <li>
                            <a href="/#akomodations" onClick={BtnAkomondations}>
                              Akomodations
                            </a>
                          </li>
                          <li>
                            <a href="/#packages" onClick={BtnPackages}>
                              Packages
                            </a>
                          </li>
                          <li>
                            <a href="/#event" onClick={BtnEvent}>
                              Event
                            </a>
                          </li>
                        </ul>
                        <div className="wrap__button" id="viewAbout">
                          <button
                            className="btn btn-custom btn-view"
                            onClick={() => bookNow()}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <nav className={sidebar ? 'side-nav slide-in' : 'side-nav'}>
            <div id="nav-icon" className="hamburger">
              <span className="bar" onClick={showSidebar}></span>
            </div>
            <ul className="side-nav-list">
              <li className="side-nav-item">
                <a className="side-nav-link" href="/site">
                  Home
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#aboutus"
                  onClick={BtnAbout}
                >
                  About Us
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#campsite"
                  onClick={BtnCampsite}
                >
                  3 Spot Campsite
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#akomodations"
                  onClick={BtnAkomondations}
                >
                  Akomodations
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#packages"
                  onClick={BtnPackages}
                >
                  Packages
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="/#event" onClick={BtnEvent}>
                  Event
                </a>
              </li>
              <li className="side-nav-item">
                <div className="sosmed">
                  <a href={settings.fb} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-facebook"></i>
                    </span>
                  </a>
                  <a href={settings.ig} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                  <a href={settings.yt} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-youtube"></i>
                    </span>
                  </a>
                </div>
              </li>
              <li className="side-nav-item">
                <div className="wrap__button">
                  <button
                    className="btn btn-custom btn-view"
                    onClick={() => bookNow()}
                  >
                    Book Now
                  </button>
                </div>
              </li>
            </ul>
          </nav>

          <div className="sec__photo">
            <div className="container">
              <div className="heading pt-4" data-aos="fade-up">
                <h2>{galleryT}</h2>
                <h6>{galleryST}</h6>
              </div>
              <div className="row">
                <div className="col-lg-12" data-aos="fade-up">
                  <ul>
                    {listgallerys.map((item, i) => {
                      return (
                        <li>
                          <img src={item.img} alt="a" loading="lazy" />
                        </li>
                      );
                    })}
                    ;
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <p>{settings.footer}</p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Themes;
