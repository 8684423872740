import React from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { bannerEdit, bannerDelete } from '../../store/action/bannerAction.js';
import { eventDelete, eventEdit } from '../../store/action/eventAction.js';
import {
  akomodationEdit,
  akomodationGallery,
  akomodationShow,
  akomodationDelete,
} from '../../store/action/akomodationAction.js';

import {
  packageEdit,
  packageGallery,
  packageShow,
  packageDelete,
} from '../../store/action/packageAction.js';

import {
  campsiteEdit,
  campsiteGallery,
  campsiteShow,
  campsiteDelete,
} from '../../store/action/campsiteAction.js';

import {
  galleryEdit,
  galleryDelete,
} from '../../store/action/galleryAction.js';

import { userEdit, userDelete } from '../../store/action/userAction.js';

export default function BTNDatatable(props) {
  let dispatch = useDispatch();
  // let history = useHistory();
  function actionDelete(id, label) {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        if (label === 'banner') {
          dispatch(bannerDelete(id));
        } else if (label === 'event') {
          dispatch(eventDelete(id));
        } else if (label === 'akomodation') {
          dispatch(akomodationDelete(id));
        } else if (label === 'package') {
          dispatch(packageDelete(id));
        } else if (label === 'campsite') {
          dispatch(campsiteDelete(id));
        } else if (label === 'gallery') {
          dispatch(galleryDelete(id));
        } else if (label === 'user') {
          dispatch(userDelete(id));
        }
      } else {
        swal('Your data is safe!');
      }
    });
  }

  function actionEdit(id, label) {
    if (label === 'banner') {
      dispatch(bannerEdit(id));
      localStorage.setItem('bannerEditID', id);
    } else if (label === 'event') {
      dispatch(eventEdit(id));
      localStorage.setItem('eventID', id);
    } else if (label === 'akomodation') {
      dispatch(akomodationEdit(id));
      localStorage.setItem('akomodationEditID', id);
    } else if (label === 'package') {
      dispatch(packageEdit(id));
      localStorage.setItem('packageEditID', id);
    } else if (label === 'campsite') {
      dispatch(campsiteEdit(id));
      localStorage.setItem('campsiteEditID', id);
    } else if (label === 'gallery') {
      dispatch(galleryEdit(id));
      localStorage.setItem('galleryEditID', id);
    } else if (label === 'user') {
      dispatch(userEdit(id));
      localStorage.setItem('userEditID', id);
    }
  }

  function actionView(id, label) {
    if (label === 'banner') {
      dispatch(bannerEdit(id));
      localStorage.setItem('bannerID', id);
    } else if (label === 'event') {
      localStorage.setItem('eventID', id);
    } else if (label === 'akomodation') {
      dispatch(akomodationShow(id));
      dispatch(akomodationGallery(id));
      localStorage.setItem('akomodationViewID', id);
    } else if (label === 'package') {
      dispatch(packageShow(id));
      dispatch(packageGallery(id));
      localStorage.setItem('packageViewID', id);
    } else if (label === 'campsite') {
      dispatch(campsiteShow(id));
      dispatch(campsiteGallery(id));
      localStorage.setItem('campsiteViewID', id);
    }
  }

  return (
    <>
      <button
        id={props.id}
        className="btn btn-sm btn-primary"
        onClick={() => actionEdit(props.id, props.label)}
      >
        Edit
      </button>
      <button
        className="btn btn-sm btn-danger"
        id={props.id}
        onClick={() => actionDelete(props.id, props.label)}
      >
        Delete
      </button>
      {props.label === 'event' ||
      props.label === 'banner' ||
      props.label === 'gallery' ||
      props.label === 'user' ? (
        ''
      ) : (
        <button
          className="btn btn-sm btn-success"
          id={props.id}
          onClick={() => actionView(props.id, props.label)}
        >
          View
        </button>
      )}
    </>
  );
}
