import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';

export default function Pagination(props) {
  return (
    <div className="table-responsive">
      <MDBDataTableV5
        bordered
        entriesOptions={[5, 20, 25]}
        entries={5}
        pagesAmount={4}
        data={props}
        fullPagination
        className="container"
      />
    </div>
  );
}
