export const PACKAGE_LIST_REQUEST = 'PACKAGE_LIST_REQUEST';
export const PACKAGE_LIST_SUCCESS = 'PACKAGE_LIST_SUCCESS';
export const PACKAGE_LIST_FAIL = 'PACKAGE_LIST_FAIL';

export const PACKAGE_SAVE_REQUEST = 'PACKAGE_SAVE_REQUEST';
export const PACKAGE_SAVE_SUCCESS = 'PACKAGE_SAVE_SUCCESS';
export const PACKAGE_SAVE_FAIL = 'PACKAGE_SAVE_FAIL';
export const PACKAGE_SAVE_VALIDATION = 'PACKAGE_SAVE_VALIDATION';

export const PACKAGE_EDIT_REQUEST = 'PACKAGE_EDIT_REQUEST';
export const PACKAGE_EDIT_SUCCESS = 'PACKAGE_EDIT_SUCCESS';
export const PACKAGE_EDIT_FAIL = 'PACKAGE_EDIT_FAIL';

export const PACKAGE_GALLERY_REQUEST = 'PACKAGE_GALLERY_REQUEST';
export const PACKAGE_GALLERY_SUCCESS = 'PACKAGE_GALLERY_SUCCESS';
export const PACKAGE_GALLERY_FAIL = 'PACKAGE_GALLERY_FAIL';

export const PACKAGE_UPDATE_REQUEST = 'PACKAGE_UPDATE_REQUEST';
export const PACKAGE_UPDATE_SUCCESS = 'PACKAGE_UPDATE_SUCCESS';
export const PACKAGE_UPDATE_FAIL = 'PACKAGE_UPDATE_FAIL';
export const PACKAGE_UPDATE_VALIDATION = 'PACKAGE_UPDATE_VALIDATION';

export const PACKAGE_DELETE_REQUEST = 'PACKAGE_DELETE_REQUEST';
export const PACKAGE_DELETE_SUCCESS = 'PACKAGE_DELETE_SUCCESS';
export const PACKAGE_DELETE_FAIL = 'PACKAGE_DELETE_FAIL';

export const PACKAGE_VIEW_REQUEST = 'PACKAGE_VIEW_REQUEST';
export const PACKAGE_VIEW_SUCCESS = 'PACKAGE_VIEW_SUCCESS';
export const PACKAGE_VIEW_FAIL = 'PACKAGE_VIEW_FAIL';

export const PACKAGE_SHOW_REQUEST = 'PACKAGE_SHOW_REQUEST';
export const PACKAGE_SHOW_SUCCESS = 'PACKAGE_SHOW_SUCCESS';
export const PACKAGE_SHOW_FAIL = 'PACKAGE_SHOW_FAIL';
