/*!

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect } from 'react';
import Chart from 'chart.js';
import { Container, Row, Col } from 'reactstrap';

// core components
import { chartOptions, parseOptions } from 'variables/charts.js';

import Header from 'components/Headers/Header.js';
import { useDispatch, useSelector } from 'react-redux';
import { eventShow } from '../store/action/eventAction.js';
import { settingEdit } from '../store/action/settingAction.js';

const Index = props => {
  let dispatch = useDispatch();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    dispatch(eventShow('1'));
    dispatch(settingEdit('1'));
  }, [dispatch]);

  const dataEvent = useSelector(state => state.eventShow);
  const { events } = dataEvent;

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            {events ? (
              <div className="sec__event">
                <div className="wrap__content">
                  <img
                    src={events.img}
                    alt=""
                    className="img-fluid img__content"
                  />
                  <div className="title__content" data-aos="fade-up">
                    <img
                      src={settings.logo}
                      className="img-fluid img__logo"
                      alt=""
                    />
                    <h2>{events.title}</h2>
                    <h1>{events.subtitle}</h1>
                    <div
                      className="action__content"
                      data-aos="fade-up"
                      data-aos-duration="6000"
                    ></div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
