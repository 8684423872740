/*!

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import { useEffect } from 'react';

import { useDispatch, useSelector, Provider } from 'react-redux';
import { settingEdit } from '../../store/action/settingAction.js';

const Login = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingEdit('1'));
  }, [dispatch]);

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                {settings.footer}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
