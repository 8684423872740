export const AKOMODATION_LIST_REQUEST = 'AKOMODATION_LIST_REQUEST';
export const AKOMODATION_LIST_SUCCESS = 'AKOMODATION_LIST_SUCCESS';
export const AKOMODATION_LIST_FAIL = 'AKOMODATION_LIST_FAIL';

export const AKOMODATION_SAVE_REQUEST = 'AKOMODATION_SAVE_REQUEST';
export const AKOMODATION_SAVE_SUCCESS = 'AKOMODATION_SAVE_SUCCESS';
export const AKOMODATION_SAVE_FAIL = 'AKOMODATION_SAVE_FAIL';
export const AKOMODATION_SAVE_VALIDATION = 'AKOMODATION_SAVE_VALIDATION';

export const AKOMODATION_EDIT_REQUEST = 'AKOMODATION_EDIT_REQUEST';
export const AKOMODATION_EDIT_SUCCESS = 'AKOMODATION_EDIT_SUCCESS';
export const AKOMODATION_EDIT_FAIL = 'AKOMODATION_EDIT_FAIL';

export const AKOMODATION_GALLERY_REQUEST = 'AKOMODATION_GALLERY_REQUEST';
export const AKOMODATION_GALLERY_SUCCESS = 'AKOMODATION_GALLERY_SUCCESS';
export const AKOMODATION_GALLERY_FAIL = 'AKOMODATION_GALLERY_FAIL';

export const AKOMODATION_UPDATE_REQUEST = 'AKOMODATION_UPDATE_REQUEST';
export const AKOMODATION_UPDATE_SUCCESS = 'AKOMODATION_UPDATE_SUCCESS';
export const AKOMODATION_UPDATE_FAIL = 'AKOMODATION_UPDATE_FAIL';
export const AKOMODATION_UPDATE_VALIDATION = 'AKOMODATION_UPDATE_VALIDATION';

export const AKOMODATION_DELETE_REQUEST = 'AKOMODATION_DELETE_REQUEST';
export const AKOMODATION_DELETE_SUCCESS = 'AKOMODATION_DELETE_SUCCESS';
export const AKOMODATION_DELETE_FAIL = 'AKOMODATION_DELETE_FAIL';

export const AKOMODATION_VIEW_REQUEST = 'AKOMODATION_VIEW_REQUEST';
export const AKOMODATION_VIEW_SUCCESS = 'AKOMODATION_VIEW_SUCCESS';
export const AKOMODATION_VIEW_FAIL = 'AKOMODATION_VIEW_FAIL';

export const AKOMODATION_SHOW_REQUEST = 'AKOMODATION_SHOW_REQUEST';
export const AKOMODATION_SHOW_SUCCESS = 'AKOMODATION_SHOW_SUCCESS';
export const AKOMODATION_SHOW_FAIL = 'AKOMODATION_SHOW_FAIL';
