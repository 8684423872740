/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useEffect } from 'react';

import { useDispatch, useSelector, Provider } from 'react-redux';
import { settingEdit } from '../../store/action/settingAction.js';

const Footer = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingEdit('1'));
  }, [dispatch]);

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="12">
          <div className="copyright text-center text-xl-left text-muted">
            {settings.footer}
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
