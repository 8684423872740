import {
  AKOMODATION_LIST_FAIL,
  AKOMODATION_LIST_REQUEST,
  AKOMODATION_LIST_SUCCESS,
  AKOMODATION_SAVE_FAIL,
  AKOMODATION_SAVE_REQUEST,
  AKOMODATION_SAVE_SUCCESS,
  AKOMODATION_SAVE_VALIDATION,
  AKOMODATION_DELETE_FAIL,
  AKOMODATION_DELETE_REQUEST,
  AKOMODATION_DELETE_SUCCESS,
  AKOMODATION_EDIT_FAIL,
  AKOMODATION_EDIT_REQUEST,
  AKOMODATION_EDIT_SUCCESS,
  AKOMODATION_SHOW_FAIL,
  AKOMODATION_SHOW_REQUEST,
  AKOMODATION_SHOW_SUCCESS,
  AKOMODATION_GALLERY_FAIL,
  AKOMODATION_GALLERY_REQUEST,
  AKOMODATION_GALLERY_SUCCESS,
  AKOMODATION_VIEW_FAIL,
  AKOMODATION_VIEW_REQUEST,
  AKOMODATION_VIEW_SUCCESS,
  AKOMODATION_UPDATE_FAIL,
  AKOMODATION_UPDATE_REQUEST,
  AKOMODATION_UPDATE_SUCCESS,
  AKOMODATION_UPDATE_VALIDATION,
} from '../contans/akomodationConst';
import axios from 'axios';

export const getakomodationList = status => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_LIST_REQUEST,
    });

    if (status) {
      const { data } = await axios.get(`akomodations/status/` + status, {});
      dispatch({
        type: AKOMODATION_LIST_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`akomodations`, {});
      dispatch({
        type: AKOMODATION_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_LIST_FAIL,
      payload: err,
    });
  }
};

export const akomodationSave =
  (
    title,
    subtitle,
    image1,
    image2,
    image3,
    desc,
    url,
    hargabiasa,
    hargaweekend,
    hargalibur,
    status
  ) =>
  async dispatch => {
    try {
      dispatch({
        type: AKOMODATION_SAVE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img1', image1);
      formData.append('img2', image2);
      formData.append('img3', image3);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('harga_biasa', hargabiasa);
      formData.append('harga_weekend', hargaweekend);
      formData.append('harga_libur', hargalibur);
      formData.append('status', status);

      await axios.post(`akomodations`, formData, config);
      dispatch({
        type: AKOMODATION_SAVE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: AKOMODATION_SAVE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: AKOMODATION_SAVE_FAIL,
          payload: err,
        });
      }
    }
  };

export const akomodationDelete = id => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_DELETE_REQUEST,
    });

    await axios.delete(`akomodations/${id}`);

    dispatch({
      type: AKOMODATION_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_DELETE_FAIL,
      payload: err,
    });
  }
};

export const akomodationEdit = (id, slug) => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_EDIT_REQUEST,
    });

    if (slug) {
      const { data } = await axios.get(`akomodations/slug/${slug}`);
      dispatch({
        type: AKOMODATION_EDIT_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(`akomodations/${id}`);
      dispatch({
        type: AKOMODATION_EDIT_SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_EDIT_FAIL,
      payload: err,
    });
  }
};

export const akomodationShow = id => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_SHOW_REQUEST,
    });

    const { data } = await axios.get(`akomodations/${id}`);
    dispatch({
      type: AKOMODATION_SHOW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_SHOW_FAIL,
      payload: err,
    });
  }
};

export const akomodationGallery = id => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_GALLERY_REQUEST,
    });

    const { data } = await axios.get(`gallery/akomodation/${id}`);
    dispatch({
      type: AKOMODATION_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_GALLERY_FAIL,
      payload: err,
    });
  }
};

export const akomodationView = id => async dispatch => {
  try {
    dispatch({
      type: AKOMODATION_VIEW_REQUEST,
    });

    const { data } = await axios.get(`akomodations/limit/${id}`);
    dispatch({
      type: AKOMODATION_VIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: AKOMODATION_VIEW_FAIL,
      payload: err,
    });
  }
};

export const akomodationUpdate =
  (
    id,
    title,
    subtitle,
    image1,
    image2,
    image3,
    desc,
    url,
    hargabiasa,
    hargaweekend,
    hargalibur,
    status
  ) =>
  async dispatch => {
    try {
      dispatch({
        type: AKOMODATION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img1', image1);
      formData.append('img2', image2);
      formData.append('img3', image3);
      formData.append('desc', desc);
      formData.append('url', url);
      formData.append('harga_biasa', hargabiasa);
      formData.append('harga_weekend', hargaweekend);
      formData.append('harga_libur', hargalibur);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`akomodations/${id}`, formData, config);

      dispatch({
        type: AKOMODATION_UPDATE_SUCCESS,
      });
      // history.push('/BANNER-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: AKOMODATION_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: AKOMODATION_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
