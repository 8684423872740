export const SECTION_LIST_REQUEST = 'SECTION_LIST_REQUEST';
export const SECTION_LIST_SUCCESS = 'SECTION_LIST_SUCCESS';
export const SECTION_LIST_FAIL = 'SECTION_LIST_FAIL';

export const SECTION_SAVE_REQUEST = 'SECTION_SAVE_REQUEST';
export const SECTION_SAVE_SUCCESS = 'SECTION_SAVE_SUCCESS';
export const SECTION_SAVE_FAIL = 'SECTION_SAVE_FAIL';
export const SECTION_SAVE_VALIDATION = 'SECTION_SAVE_VALIDATION';

export const SECTION_EDIT_REQUEST = 'SECTION_EDIT_REQUEST';
export const SECTION_EDIT_SUCCESS = 'SECTION_EDIT_SUCCESS';
export const SECTION_EDIT_FAIL = 'SECTION_EDIT_FAIL';

export const SECTION_UPDATE_REQUEST = 'SECTION_UPDATE_REQUEST';
export const SECTION_UPDATE_SUCCESS = 'SECTION_UPDATE_SUCCESS';
export const SECTION_UPDATE_FAIL = 'SECTION_UPDATE_FAIL';
export const SECTION_UPDATE_VALIDATION = 'SECTION_UPDATE_VALIDATION';

export const SECTION_DELETE_REQUEST = 'SECTION_DELETE_REQUEST';
export const SECTION_DELETE_SUCCESS = 'SECTION_DELETE_SUCCESS';
export const SECTION_DELETE_FAIL = 'SECTION_DELETE_FAIL';
