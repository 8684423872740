/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState, useEffect } from 'react';
import Aos from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import { settingEdit } from '../store/action/settingAction.js';
import {
  getcampsiteList,
  campsiteEdit,
  campsiteGallery,
} from '../store/action/campsiteAction.js';

// loader
import ScaleLoader from 'react-spinners/ScaleLoader';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// css
import '../assets/css/Home.css';

const Themes = props => {
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  // const params = useParams();
  // console.log(params);
  let history = useHistory();
  let dispatch = useDispatch();

  const idDetail = localStorage.getItem('campsiteDetail');
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    const params = history.location.pathname;
    const paramsArray = params.split('/');
    let idparam = paramsArray[2];

    dispatch(getcampsiteList('0'));
    dispatch(campsiteEdit(false, idparam));
    dispatch(settingEdit('1'));
  }, [dispatch, idDetail, history]);

  const datacampsiteEdit = useSelector(state => state.campsiteEdit);
  const { campsites } = datacampsiteEdit;

  useEffect(() => {
    dispatch(campsiteGallery(campsites.id));
  }, [dispatch, campsites]);

  const datacampsiteGallery = useSelector(state => state.campsiteGallery);
  const { campsitesGallery } = datacampsiteGallery;

  const datacampsite = useSelector(state => state.campsiteList);
  const { listcampsites } = datacampsite;

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  useEffect(() => {
    Aos.init();
  }, []);

  function btnDetail(slug, id) {
    history.push(slug);
    localStorage.removeItem('campsiteDetail');
    localStorage.setItem('campsiteDetail', id);
  }

  const aboutSection = useRef(null);
  const BtnAbout = props => {
    window.scrollTo({
      top: aboutSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const campsiteSection = useRef(null);
  const BtnCampsite = () => {
    window.scrollTo({
      top: campsiteSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const akomondationsSection = useRef(null);
  const BtnAkomondations = () => {
    window.scrollTo({
      top: akomondationsSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const packagesSection = useRef(null);
  const BtnPackages = () => {
    window.scrollTo({
      top: packagesSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const eventSection = useRef(null);
  const BtnEvent = () => {
    window.scrollTo({
      top: eventSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  // navbar
  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 30
        ? setStickyClass('navbar-active')
        : setStickyClass('relative');
    }
  };

  //btn link send whatsapp
  const bookNow = e => {
    window.open(
      'https://api.whatsapp.com/send?phone=' +
        settings.telp +
        '&text=Hallo batu Camp! Saya ingin booking%20',
      '_blank'
    );
  };

  return (
    <>
      {loading ? (
        <div className="loader-spin">
          <ScaleLoader color={'#03441A'} loading={loading} size={500} />
        </div>
      ) : (
        <div className="my-app">
          <div id="navbar" className={'navbar-active ' + stickyClass}>
            <div className="sec__navbar">
              <div className="container">
                <div className="topnavbar">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col">
                      <div className="topleft">
                        <div className="icall">
                          <a
                            href={'tel:+' + settings.telp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>
                              <i className="fas fa-phone-volume fa-fw"></i>
                            </span>
                          </a>
                        </div>
                        <div className="title">
                          <p>For </p>
                          <p>+{settings.telp}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col text-center">
                      <img src={settings.logo} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4 col text-right m-auto">
                      <div className="topright">
                        <span className="btnbar pl-4" onClick={showSidebar}>
                          <i className="fas fa-bars fa-fw"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <nav className="nav-bottom">
                  <div className="row wrap__content">
                    <div className="col-lg-2 col-12 order1">
                      <div className="menu__res">
                        <a href={settings.fb} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-facebook"></i>
                          </span>
                        </a>
                        <a href={settings.ig} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-instagram"></i>
                          </span>
                        </a>
                        <a href={settings.yt} target="_blank" rel="noreferrer">
                          <span>
                            <i className="fab fa-youtube"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-10 col-12 order2">
                      <div className="wrap__menu just">
                        <ul>
                          <li className="active">
                            <a href="/site">Home</a>
                          </li>
                          <li>
                            <a href="/#aboutus" onClick={BtnAbout}>
                              About Us
                            </a>
                          </li>
                          <li>
                            <a href="/#campsite" onClick={BtnCampsite}>
                              3 Spot Campsite
                            </a>
                          </li>
                          <li>
                            <a href="/#akomodations" onClick={BtnAkomondations}>
                              Akomodations
                            </a>
                          </li>
                          <li>
                            <a href="/#packages" onClick={BtnPackages}>
                              Packages
                            </a>
                          </li>
                          <li>
                            <a href="/#event" onClick={BtnEvent}>
                              Event
                            </a>
                          </li>
                        </ul>
                        <div className="wrap__button" id="viewAbout">
                          <button
                            className="btn btn-custom btn-view"
                            onClick={() => bookNow()}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <nav className={sidebar ? 'side-nav slide-in' : 'side-nav'}>
            <div id="nav-icon" className="hamburger">
              <span className="bar" onClick={showSidebar}></span>
            </div>
            <ul className="side-nav-list">
              <li className="side-nav-item">
                <a className="side-nav-link" href="/site">
                  Home
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#aboutus"
                  onClick={BtnAbout}
                >
                  About Us
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#campsite"
                  onClick={BtnCampsite}
                >
                  3 Spot Campsite
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#akomodations"
                  onClick={BtnAkomondations}
                >
                  Akomodations
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="/#packages"
                  onClick={BtnPackages}
                >
                  Packages
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="/#event" onClick={BtnEvent}>
                  Event
                </a>
              </li>
              <li className="side-nav-item">
                <div className="sosmed">
                  <a href={settings.fb} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-facebook"></i>
                    </span>
                  </a>
                  <a href={settings.ig} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                  <a href={settings.yt} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-youtube"></i>
                    </span>
                  </a>
                </div>
              </li>
              <li className="side-nav-item">
                <div className="wrap__button">
                  <button
                    className="btn btn-custom btn-view"
                    onClick={() => bookNow()}
                  >
                    Book Now
                  </button>
                </div>
              </li>
            </ul>
          </nav>

          <div className="sec__detailhigh">
            <div className="row">
              <div className="col-lg-6 col-12 m-auto order2">
                <div className="wrap__title">
                  <h2 className="animate-fadeLeft">{campsites.title}</h2>
                  <div className="p__title animate-fadeRight">
                    <p>{campsites.subtitle}</p>
                  </div>
                  {campsites.harga_biasa ? (
                    <div className="price">
                      <h4>Harga senin - jumaat : </h4>
                      <h5>{campsites.harga_biasa}</h5>
                    </div>
                  ) : (
                    ''
                  )}

                  {campsites.harga_weekend ? (
                    <div className="price">
                      <h4>Harga Sabtu - Minggu : </h4>
                      <h5>{campsites.harga_weekend}</h5>
                    </div>
                  ) : (
                    ''
                  )}

                  {campsites.harga_libur ? (
                    <div className="price">
                      <h4>Harga hari libur : </h4>
                      <h5>{campsites.harga_libur}</h5>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="button pt-3">
                    <button
                      className="btn btn-custom animate-fadeLeft"
                      onClick={() => bookNow()}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Swiper
                  effect={'coverflow'}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={'auto'}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  // pagination={true}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="wrap__content">
                      <img
                        src={campsites.img}
                        alt=""
                        className="img-fluid img__content"
                      />
                    </div>
                  </SwiperSlide>
                  {/* {slide_img.map((img, i) => {
                return (
                  
                );
              })} */}
                </Swiper>
              </div>
            </div>
          </div>

          <div className="sec__detailabout" ref={aboutSection}>
            <div className="wrap__content">
              <div className="row" data-aos="fade-left">
                <div className="col-lg-2 col-12 wrap__left">
                  <div className="title__text">
                    <h1>GALLERY</h1>
                  </div>
                </div>
                <div className="col-lg-10 col-12">
                  <div className="wrap__right">
                    <ul>
                      {campsitesGallery.map((item, i) => {
                        return (
                          <li key={i}>
                            <img
                              src={item.img}
                              alt="A Toyota Previa covered in graffiti"
                              loading="lazy"
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sec__detaildesc" ref={aboutSection}>
            <div className="wrap__content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="container">
                    <div className="wrap__card" data-aos="fade-up">
                      <div className="title__desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: campsites.desc,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {campsites.url ? (
            <div className="sec__detailvideo" ref={aboutSection}>
              <div className="wrap__content">
                <div className="row" data-aos="fade-right">
                  <div className="col-lg-10 col-12 order2">
                    <div className="wrap__right">
                      <iframe
                        className="iframe"
                        src={campsites.url}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-lg-2 col-12 wrap__left">
                    <div className="title__text">
                      <h1>PREVIEW VIDEO</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="sec__campsite" ref={campsiteSection}>
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>OTHER CAMPSITE</h2>
              </div>
              <div className="row">
                {listcampsites.map((item, i) => {
                  const str = item.title;
                  const new_str = str.replace(/\s+/g, '-');
                  const slug = new_str.toLowerCase();
                  return (
                    <div
                      className="col-lg-4 col-sm-6 col-12"
                      data-aos="fade-up"
                      key={i}
                    >
                      <div
                        className="wrap__content"
                        onClick={() => btnDetail(slug, item.id)}
                      >
                        <img
                          src={item.img}
                          className="img-fluid bg__content"
                          alt=""
                        />
                        <div className="wrap__card">
                          <div className="shape">
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                          </div>
                          <h4>Bukit Curug</h4>
                          <p>
                            Curug batu gede cisuren camping ground is a
                            beautifull waterfall landscape and good the forest
                            in puncak bogor
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <footer>
            <p>{settings.footer}</p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Themes;
