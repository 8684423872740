import {
  SECTION_LIST_FAIL,
  SECTION_LIST_REQUEST,
  SECTION_LIST_SUCCESS,
  SECTION_SAVE_REQUEST,
  SECTION_SAVE_SUCCESS,
  SECTION_SAVE_FAIL,
  SECTION_EDIT_REQUEST,
  SECTION_EDIT_SUCCESS,
  SECTION_EDIT_FAIL,
  SECTION_UPDATE_REQUEST,
  SECTION_UPDATE_SUCCESS,
  SECTION_UPDATE_FAIL,
  SECTION_UPDATE_VALIDATION,
} from '../contans/sectionConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
import swal from 'sweetalert';
const initialState = {
  validation: [],
};
export const sectionListReducer = (
  state = { listSection: [], columns: [] },
  action
) => {
  switch (action.type) {
    case SECTION_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case SECTION_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        {
          label: 'title',
          field: 'title',
          width: 200,
        },
        {
          label: 'sub title',
          field: 'subtitle',
          width: 100,
        },
        {
          label: 'kategori',
          field: 'kategori',
          width: 100,
        },
        {
          label: 'updated by',
          field: 'updated_by',
          width: 150,
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },
      ];

      for (let i = 0; i < data.length; i++) {
        var btn = (
          <BTNDatatable id={data[i].id} url={'sections/' + data[i].id} />
        );
        data[i].action = btn;
      }
      return {
        ...state,
        loading: false,
        listSection: data,
        columns: columns,
      };
    case SECTION_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bannerCreateReducer = (state = { content: [] }, action) => {
  switch (action.type) {
    case SECTION_SAVE_REQUEST:
      return { ...state, loading: true, error: [] };
    case SECTION_SAVE_SUCCESS:
      return { ...state, content: 'Insert success', loading: false, error: [] };
    case SECTION_SAVE_FAIL:
      return {
        ...state,
        content: 'Insert failed',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const sectionEditReducer = (state = { sections: {} }, action) => {
  switch (action.type) {
    case SECTION_EDIT_REQUEST:
      return { ...state, loading: true, error: [] };
    case SECTION_EDIT_SUCCESS:
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false, sections: action.payload.data };
    case SECTION_EDIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const settingUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECTION_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case SECTION_UPDATE_SUCCESS:
      // message.success({ content: 'Update success', key });
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case SECTION_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case SECTION_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
