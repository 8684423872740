import {
  SETTING_LIST_FAIL,
  SETTING_LIST_REQUEST,
  SETTING_LIST_SUCCESS,
  SETTING_EDIT_FAIL,
  SETTING_EDIT_REQUEST,
  SETTING_EDIT_SUCCESS,
  SETTING_UPDATE_FAIL,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_VALIDATION,
} from '../contans/settingConst';
import axios from 'axios';

export const getsettingList = async dispatch => {
  // export const settingList = (page, search) => async dispatch => {
  try {
    dispatch({
      type: SETTING_LIST_REQUEST,
    });

    const { data } = await axios.get(`settings`, {
      //   params: {
      //     page: page,
      //     search: search,
      //   },
    });

    dispatch({
      type: SETTING_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: SETTING_LIST_FAIL,
      payload: err,
    });
  }
};

export const settingEdit = id => async dispatch => {
  try {
    dispatch({
      type: SETTING_EDIT_REQUEST,
    });

    const { data } = await axios.get(`settings/${id}`);
    dispatch({
      type: SETTING_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: SETTING_EDIT_FAIL,
      payload: err,
    });
  }
};

export const settingUpdate =
  (id, company, logo, video, map, footer, fb, ig, yt, telp) =>
  async dispatch => {
    try {
      dispatch({
        type: SETTING_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('company', company);
      formData.append('logo', logo);
      formData.append('video', video);
      formData.append('map', map);
      formData.append('footer', footer);
      formData.append('fb', fb);
      formData.append('ig', ig);
      formData.append('yt', yt);
      formData.append('telp', telp);
      formData.append('_method', 'PUT');

      await axios.post(`settings/${id}`, formData, config);

      dispatch({
        type: SETTING_UPDATE_SUCCESS,
      });
      // history.push('/SETTING-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: SETTING_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: SETTING_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
