import {
  GALLERY_LIST_FAIL,
  GALLERY_LIST_REQUEST,
  GALLERY_LIST_SUCCESS,
  GALLERY_SAVE_FAIL,
  GALLERY_SAVE_REQUEST,
  GALLERY_SAVE_SUCCESS,
  GALLERY_SAVE_VALIDATION,
  GALLERY_DELETE_FAIL,
  GALLERY_DELETE_REQUEST,
  GALLERY_DELETE_SUCCESS,
  GALLERY_EDIT_FAIL,
  GALLERY_EDIT_REQUEST,
  GALLERY_EDIT_SUCCESS,
  GALLERY_LIMIT_FAIL,
  GALLERY_LIMIT_REQUEST,
  GALLERY_LIMIT_SUCCESS,
  GALLERY_UPDATE_FAIL,
  GALLERY_UPDATE_REQUEST,
  GALLERY_UPDATE_SUCCESS,
  GALLERY_UPDATE_VALIDATION,
} from '../contans/galleryConst';
import axios from 'axios';

export const getgalleryList = async dispatch => {
  try {
    dispatch({
      type: GALLERY_LIST_REQUEST,
    });

    const { data } = await axios.get(`gallery`, {
      //   params: {
      //     page: page,
      //     search: search,
      //   },
    });

    dispatch({
      type: GALLERY_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: GALLERY_LIST_FAIL,
      payload: err,
    });
  }
};

export const gallerySave =
  (kategori, idkategori, titlekategori, image, ordering, status) =>
  async dispatch => {
    try {
      dispatch({
        type: GALLERY_SAVE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('kategori', kategori);
      formData.append('idkategori', idkategori);
      formData.append('title_kategori', titlekategori);
      formData.append('img', image);
      formData.append('ordering', ordering);
      formData.append('status', status);

      await axios.post(`gallery`, formData, config);
      dispatch({
        type: GALLERY_SAVE_SUCCESS,
      });
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: GALLERY_SAVE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: GALLERY_SAVE_FAIL,
          payload: err,
        });
      }
    }
  };

export const galleryDelete = id => async dispatch => {
  try {
    dispatch({
      type: GALLERY_DELETE_REQUEST,
    });

    await axios.delete(`gallery/${id}`);

    dispatch({
      type: GALLERY_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: GALLERY_DELETE_FAIL,
      payload: err,
    });
  }
};

export const galleryEdit = id => async dispatch => {
  try {
    dispatch({
      type: GALLERY_EDIT_REQUEST,
    });

    const { data } = await axios.get(`gallery/${id}`);
    dispatch({
      type: GALLERY_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: GALLERY_EDIT_FAIL,
      payload: err,
    });
  }
};

export const limitGallery = id => async dispatch => {
  try {
    dispatch({
      type: GALLERY_LIMIT_REQUEST,
    });

    const { data } = await axios.get(`gallery/limit/${id}`);
    dispatch({
      type: GALLERY_LIMIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: GALLERY_LIMIT_FAIL,
      payload: err,
    });
  }
};

export const galleryUpdate =
  (id, kategori, idkategori, titlekategori, image, ordering, status) =>
  async dispatch => {
    try {
      dispatch({
        type: GALLERY_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('kategori', kategori);
      formData.append('idkategori', idkategori);
      formData.append('title_kategori', titlekategori);
      formData.append('img', image);
      formData.append('ordering', ordering);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`banners/${id}`, formData, config);

      dispatch({
        type: GALLERY_UPDATE_SUCCESS,
      });
      // history.push('/BANNER-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: GALLERY_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: GALLERY_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
