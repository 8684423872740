export const BANNER_LIST_REQUEST = 'BANNER_LIST_REQUEST';
export const BANNER_LIST_SUCCESS = 'BANNER_LIST_SUCCESS';
export const BANNER_LIST_FAIL = 'BANNER_LIST_FAIL';

export const BANNER_SAVE_REQUEST = 'BANNER_SAVE_REQUEST';
export const BANNER_SAVE_SUCCESS = 'BANNER_SAVE_SUCCESS';
export const BANNER_SAVE_FAIL = 'BANNER_SAVE_FAIL';
export const BANNER_SAVE_VALIDATION = 'BANNER_SAVE_VALIDATION';

export const BANNER_EDIT_REQUEST = 'BANNER_EDIT_REQUEST';
export const BANNER_EDIT_SUCCESS = 'BANNER_EDIT_SUCCESS';
export const BANNER_EDIT_FAIL = 'BANNER_EDIT_FAIL';

export const BANNER_UPDATE_REQUEST = 'BANNER_UPDATE_REQUEST';
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS';
export const BANNER_UPDATE_FAIL = 'BANNER_UPDATE_FAIL';
export const BANNER_UPDATE_VALIDATION = 'BANNER_UPDATE_VALIDATION';

export const BANNER_DELETE_REQUEST = 'BANNER_DELETE_REQUEST';
export const BANNER_DELETE_SUCCESS = 'BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_FAIL = 'BANNER_DELETE_FAIL';
