/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Register from 'views/examples/Register.js';
// import Login from 'views/examples/Login.js';
import Index from 'views/Index.js';
// import Profile from 'views/examples/Profile.js';
// import Maps from 'views/examples/Maps.js';
// import Tables from 'views/examples/Tables.js';
// import Icons from 'views/examples/Icons.js';

import Banner from 'views/pages/Banner.js';
import Users from 'views/pages/Users.js';
import Campsite from 'views/pages/Campsite.js';
import Akomodation from 'views/pages/Akomodation.js';
import Gallery from 'views/pages/Gallery.js';
import Package from 'views/pages/Package.js';
import Section from 'views/pages/Section.js';
import Setting from 'views/pages/Setting.js';
import Events from 'views/pages/Event.js';

var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
  },
  {
    path: '/banner',
    name: 'Banners',
    icon: 'ni ni-image text-yellow',
    component: Banner,
    layout: '/admin',
  },
  {
    path: '/campsite',
    name: 'Campsites',
    icon: 'ni ni-satisfied text-success',
    component: Campsite,
    layout: '/admin',
  },
  {
    path: '/akomondation',
    name: 'Akomondations',
    icon: 'ni ni-spaceship text-yellow',
    component: Akomodation,
    layout: '/admin',
  },
  {
    path: '/package',
    name: 'Packages',
    icon: 'ni ni-collection text-red',
    component: Package,
    layout: '/admin',
  },
  {
    path: '/gallery',
    name: 'Gallery',
    icon: 'ni ni-album-2 text-success',
    component: Gallery,
    layout: '/admin',
  },
  {
    path: '/event',
    name: 'Events',
    icon: 'ni ni-tie-bow text-orange',
    component: Events,
    layout: '/admin',
  },
  {
    path: '/section-title',
    name: 'Section Title',
    icon: 'ni ni-tag text-info',
    component: Section,
    layout: '/admin',
  },
  {
    path: '/setting-website',
    name: 'Setting Website',
    icon: 'ni ni-settings text-grey',
    component: Setting,
    layout: '/admin',
  },
  {
    path: '/user-akses',
    name: 'User Akses',
    icon: 'ni ni-single-02 text-dark',
    component: Users,
    layout: '/admin',
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   icon: 'ni ni-key-25 text-info',
  //   component: Login,
  //   layout: '/auth',
  // },
];
export default routes;
