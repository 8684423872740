export const SETTING_LIST_REQUEST = 'SETTING_LIST_REQUEST';
export const SETTING_LIST_SUCCESS = 'SETTING_LIST_SUCCESS';
export const SETTING_LIST_FAIL = 'SETTING_LIST_FAIL';

export const SETTING_EDIT_REQUEST = 'SETTING_EDIT_REQUEST';
export const SETTING_EDIT_SUCCESS = 'SETTING_EDIT_SUCCESS';
export const SETTING_EDIT_FAIL = 'SETTING_EDIT_FAIL';

export const SETTING_UPDATE_REQUEST = 'SETTING_UPDATE_REQUEST';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAIL = 'SETTING_UPDATE_FAIL';
export const SETTING_UPDATE_VALIDATION = 'SETTING_UPDATE_VALIDATION';
