import {
  EVENT_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_SAVE_FAIL,
  EVENT_SAVE_REQUEST,
  EVENT_SAVE_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_EDIT_FAIL,
  EVENT_EDIT_REQUEST,
  EVENT_EDIT_SUCCESS,
  EVENT_SHOW_FAIL,
  EVENT_SHOW_REQUEST,
  EVENT_SHOW_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_VALIDATION,
} from '../contans/eventConst';
import axios from 'axios';

export const geteventList = async dispatch => {
  try {
    dispatch({
      type: EVENT_LIST_REQUEST,
    });

    const { data } = await axios.get(`events`, {
      //   params: {
      //     page: page,
      //     search: search,
      //   },
    });

    dispatch({
      type: EVENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: EVENT_LIST_FAIL,
      payload: err,
    });
  }
};

export const eventSave = (img, title, subtitle, status) => async dispatch => {
  try {
    dispatch({
      type: EVENT_SAVE_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('img', img);
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('status', status);

    await axios.post(`events`, formData, config);
    dispatch({
      type: EVENT_SAVE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: EVENT_SAVE_FAIL,
      payload: err,
    });
  }
};

export const eventDelete = id => async dispatch => {
  try {
    dispatch({
      type: EVENT_DELETE_REQUEST,
    });

    await axios.delete(`events/${id}`);

    dispatch({
      type: EVENT_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: EVENT_DELETE_FAIL,
      payload: err,
    });
  }
};

export const eventEdit = id => async dispatch => {
  try {
    dispatch({
      type: EVENT_EDIT_REQUEST,
    });

    const { data } = await axios.get(`events/${id}`);
    dispatch({
      type: EVENT_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: EVENT_EDIT_FAIL,
      payload: err,
    });
  }
};

export const eventShow = id => async dispatch => {
  try {
    dispatch({
      type: EVENT_SHOW_REQUEST,
    });

    const { data } = await axios.get(`events/view/${id}`);
    dispatch({
      type: EVENT_SHOW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: EVENT_SHOW_FAIL,
      payload: err,
    });
  }
};

export const eventUpdate =
  (id, img, title, subtitle, status) => async dispatch => {
    try {
      dispatch({
        type: EVENT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('img', img);
      formData.append('status', status);
      formData.append('_method', 'PUT');

      await axios.post(`events/${id}`, formData, config);

      dispatch({
        type: EVENT_UPDATE_SUCCESS,
      });
      // history.push('/BANNER-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: EVENT_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: EVENT_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
