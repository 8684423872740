/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';

// get data api
import { useDispatch, useSelector } from 'react-redux';

import {
  settingEdit,
  settingUpdate,
} from '../../store/action/settingAction.js';

const Banner = () => {
  // set let
  let dispatch = useDispatch();

  // set variables
  const [company, setCompany] = useState('');
  const [logo, setLogo] = useState('');
  const [video, setVideo] = useState('');
  const [maps, setMaps] = useState('');
  const [footer, setFooter] = useState('');
  const [ig, setIg] = useState('');
  const [yt, setYt] = useState('');
  const [fb, setFb] = useState('');
  const [telp, setTelp] = useState('');

  useEffect(() => {
    dispatch(settingEdit('1'));
  }, [dispatch]);

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  useMemo(() => {
    if (settings) {
      setCompany(settings.company);
      setLogo(settings.logo);
      setVideo(settings.video);
      setMaps(settings.map);
      setFooter(settings.footer);
      setIg(settings.ig);
      setYt(settings.yt);
      setFb(settings.fb);
      setTelp(settings.telp);
    }
  }, [settings]);

  // sintac create
  const handleChange = e => {
    setLogo(e.target.files[0]);
  };

  const handleSubmit = () => {
    dispatch(
      settingUpdate('1', company, logo, video, maps, footer, fb, ig, yt, telp)
    );

    swal('Succes! Your data has been updated!', {
      icon: 'success',
    });
    setInterval(() => {
      window.location.reload();
    }, 2000);
  };

  // set collapse
  const [showShow, setShowShow] = useState(true);
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> Detail
                          Company
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow}>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-title"
                                >
                                  Nama Company
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={company}
                                  id="input-company"
                                  name="company"
                                  placeholder="masukan company"
                                  onChange={e => {
                                    setCompany(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-image"
                                >
                                  Logo
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="file"
                                  defaultValue={logo}
                                  id="input-image"
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-subtitle"
                                >
                                  Video
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={video}
                                  id="input-video"
                                  name="video"
                                  placeholder="masukan link video youtube"
                                  onChange={e => {
                                    setVideo(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-ig"
                                >
                                  Link Instagram
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={ig}
                                  id="input-ig"
                                  placeholder="masukan link Instagram"
                                  onChange={e => {
                                    setIg(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-yt"
                                >
                                  Link Youtube
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={yt}
                                  id="input-yt"
                                  placeholder="masukan link Youtube"
                                  onChange={e => {
                                    setYt(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-fb"
                                >
                                  Link Facebook
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={fb}
                                  id="input-fb"
                                  placeholder="masukan link Facebook"
                                  onChange={e => {
                                    setFb(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-telp"
                                >
                                  Nomor Telepon
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={telp}
                                  id="input-telp"
                                  name="telp"
                                  placeholder="nomor harus berawal 62"
                                  onChange={e => {
                                    setTelp(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-footer"
                                >
                                  Footer
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={footer}
                                  id="input-footer"
                                  name="footer"
                                  placeholder="masukan footer"
                                  onChange={e => {
                                    setFooter(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit()}
                                >
                                  <i className="fa fa-plus"></i> Update
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
