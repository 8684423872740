export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

export const EVENT_SAVE_REQUEST = 'EVENT_SAVE_REQUEST';
export const EVENT_SAVE_SUCCESS = 'EVENT_SAVE_SUCCESS';
export const EVENT_SAVE_FAIL = 'EVENT_SAVE_FAIL';
export const EVENT_SAVE_VALIDATION = 'EVENT_SAVE_VALIDATION';

export const EVENT_EDIT_REQUEST = 'EVENT_EDIT_REQUEST';
export const EVENT_EDIT_SUCCESS = 'EVENT_EDIT_SUCCESS';
export const EVENT_EDIT_FAIL = 'EVENT_EDIT_FAIL';

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL';
export const EVENT_UPDATE_VALIDATION = 'EVENT_UPDATE_VALIDATION';

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL';

export const EVENT_SHOW_REQUEST = 'EVENT_SHOW_REQUEST';
export const EVENT_SHOW_SUCCESS = 'EVENT_SHOW_SUCCESS';
export const EVENT_SHOW_FAIL = 'EVENT_SHOW_FAIL';
