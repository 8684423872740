/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Form,
  Button,
  Input,
  Container,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';

import React, { useState, useEffect, useMemo } from 'react';
import { MDBCollapse } from 'mdb-react-ui-kit';
// core components
import Datatable from 'components/Datatable/DatatableBasic';

// get data api
import { useDispatch, useSelector } from 'react-redux';

import {
  getuserList,
  userSave,
  userUpdate,
} from '../../store/action/userAction.js';

const Users = () => {
  // set variables
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [labelbtn, setLabelbtn] = useState('Simpan');

  const [showShow, setShowShow] = useState(false);
  // set let
  let dispatch = useDispatch();

  // sintac edit
  const editID = localStorage.getItem('userEditID');
  useEffect(() => {
    localStorage.removeItem('userEditID');
    dispatch(getuserList);

    if (editID) {
      setLabelbtn('Update');
    }
  }, [editID, dispatch]);

  // get list

  const datauser = useSelector(state => state.userList);
  const { listusers, columns } = datauser;

  // edit
  const dataEdit = useSelector(state => state.userEdit);
  const { users, clickShow } = dataEdit;

  // isset edit
  useMemo(() => {
    if (users) {
      setName(users.name);
      setEmail(users.email);
      setPassword(users.original_password);
      setConfirmpassword(users.original_password);
      setShowShow(clickShow);
    }
  }, [users, clickShow]);

  const handleSubmit = e => {
    e.preventDefault();

    if (!email || !password || !confirmpassword || !name) {
      swal('failed! data not has empty.', {
        icon: 'error',
      });
    } else if (password !== confirmpassword) {
      swal('failed! confirm password salah.', {
        icon: 'error',
      });
    } else {
      if (users.id) {
        dispatch(userUpdate(users.id, name, email, password));
      } else {
        dispatch(userSave(name, email, password));
      }
    }
  };

  // set collapse
  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader
                    className="bg-white border-0 cursor-pointer"
                    onClick={toggleShow}
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          <i className="ni ni-fat-add text-success"></i> New
                          Users
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          <i className="ni ni-bold-down"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <MDBCollapse show={showShow} className="show-height">
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">Form</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-name"
                                >
                                  Name
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={name}
                                  id="input-name"
                                  name="name"
                                  placeholder="masukan name"
                                  onChange={e => {
                                    setName(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="text"
                                  defaultValue={email}
                                  id="input-email"
                                  name="email"
                                  placeholder="masukan email"
                                  onChange={e => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-password"
                                >
                                  Password
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="password"
                                  defaultValue={password}
                                  id="input-name"
                                  name="password"
                                  placeholder="masukan password"
                                  onChange={e => {
                                    setPassword(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-confirmpassword"
                                >
                                  Confirm Password
                                </label>
                                <Input
                                  className="form-control-alternative form-control"
                                  type="password"
                                  defaultValue={confirmpassword}
                                  id="input-confirmname"
                                  name="confirmpassword"
                                  placeholder="masukan confirm password"
                                  onChange={e => {
                                    setConfirmpassword(e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  className="my-4"
                                  color="success"
                                  type="button"
                                  onClick={e => handleSubmit(e)}
                                >
                                  <i className="fa fa-plus"></i> {labelbtn}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </MDBCollapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">User List </h3>
              </CardHeader>
              <Datatable rows={listusers} columns={columns} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
