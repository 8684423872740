import {
  SECTION_LIST_FAIL,
  SECTION_LIST_REQUEST,
  SECTION_LIST_SUCCESS,
  SECTION_EDIT_FAIL,
  SECTION_EDIT_REQUEST,
  SECTION_EDIT_SUCCESS,
  SECTION_UPDATE_FAIL,
  SECTION_UPDATE_REQUEST,
  SECTION_UPDATE_SUCCESS,
  SECTION_UPDATE_VALIDATION,
} from '../contans/sectionConst';
import axios from 'axios';

export const sectionList = async dispatch => {
  // export const bannerList = (page, search) => async dispatch => {
  try {
    dispatch({
      type: SECTION_LIST_REQUEST,
    });

    const { data } = await axios.get(`sectionmenus`, {
      //   params: {
      //     page: page,
      //     search: search,
      //   },
    });

    dispatch({
      type: SECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: SECTION_LIST_FAIL,
      payload: err,
    });
  }
};

export const sectionEdit = id => async dispatch => {
  try {
    dispatch({
      type: SECTION_EDIT_REQUEST,
    });

    const { data } = await axios.get(`sectionmenus/${id}`);
    dispatch({
      type: SECTION_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: SECTION_EDIT_FAIL,
      payload: err,
    });
  }
};

export const sectionUpdate =
  (
    aboutT,
    aboutST,
    campsiteT,
    campsiteST,
    reserveT,
    reserveST,
    packageT,
    packageST,
    galleryT,
    galleryST,
    aboutbT,
    aboutbST,
    mapsT,
    mapsST
  ) =>
  async dispatch => {
    try {
      dispatch({
        type: SECTION_UPDATE_REQUEST,
      });

      const config = {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      };

      const formData = new FormData();
      formData.append('aboutT', aboutT);
      formData.append('aboutST', aboutST);
      formData.append('campsiteT', campsiteT);
      formData.append('campsiteST', campsiteST);
      formData.append('reserveT', reserveT);
      formData.append('reserveST', reserveST);
      formData.append('packageT', packageT);
      formData.append('packageST', packageST);
      formData.append('galleryT', galleryT);
      formData.append('galleryST', galleryST);
      formData.append('aboutbT', aboutbT);
      formData.append('aboutbST', aboutbST);
      formData.append('mapsT', mapsT);
      formData.append('mapsST', mapsST);
      formData.append('_method', 'PUT');

      await axios.post(`sectionmenus/1`, formData, config);

      dispatch({
        type: SECTION_UPDATE_SUCCESS,
      });
      // history.push('/SECTION-index')
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: SECTION_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: SECTION_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
