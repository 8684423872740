import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_VALIDATION,
  USER_SAVE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_UPDATE_VALIDATION,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
} from '../contans/userConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
import swal from 'sweetalert';
const initialState = {
  validation: [],
};

export const userListReducer = (
  state = { listusers: [], columns: [] },
  action
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case USER_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        {
          label: 'ID',
          field: 'id',
          width: 200,
        },
        {
          label: 'name',
          field: 'name',
          width: 200,
        },
        {
          label: 'email',
          field: 'email',
          width: 200,
        },
        {
          label: 'password',
          field: 'original_password',
          width: 200,
        },
        {
          label: 'Action',
          field: 'action',
          width: 200,
        },
      ];
      for (let i = 0; i < data.length; i++) {
        // no++;
        var btn = (
          <BTNDatatable
            id={data[i].id}
            url={'users/' + data[i].id}
            label="user"
          />
        );
        // var img = <img src={`http:://api/img/${data[i].img}`} alt="" />;
        data[i].action = btn;
        // data[i].no = no;
      }
      return {
        ...state,
        loading: false,
        listusers: data,
        columns: columns,
      };
    case USER_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userCreateReducer = (
  state = { content: [], validation: [] },
  action
) => {
  switch (action.type) {
    case USER_SAVE_REQUEST:
      return { ...state, loading: true, error: [] };
    case USER_SAVE_SUCCESS:
      swal('Succes! Your data has been created!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, content: 'Insert success', loading: false, error: [] };
    case USER_SAVE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case USER_SAVE_FAIL:
      return {
        ...state,
        content: 'Insert failed',
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [] };
    case USER_DELETE_SUCCESS:
      swal('Poof! Your data has been deleted!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case USER_DELETE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userEditReducer = (
  state = { users: {}, clickShow: false },
  action
) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { ...state, loading: true, error: [], clickShow: false };
    case USER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        clickShow: true,
      };
    case USER_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        clickShow: false,
      };
    default:
      return state;
  }
};
export const userUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case USER_UPDATE_SUCCESS:
      swal('Succes! Your data has been updated!', {
        icon: 'success',
      });
      setInterval(() => {
        window.location.reload();
      }, 2000);
      return { ...state, loading: false };
    case USER_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case USER_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
