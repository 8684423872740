import {
  SETTING_LIST_FAIL,
  SETTING_LIST_REQUEST,
  SETTING_LIST_SUCCESS,
  SETTING_EDIT_REQUEST,
  SETTING_EDIT_SUCCESS,
  SETTING_EDIT_FAIL,
  SETTING_UPDATE_VALIDATION,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAIL,
} from '../contans/settingConst';
import BTNDatatable from 'components/Datatable/ButtonDatatable';
const initialState = {
  validation: [],
};

export const settingListReducer = (
  state = { listSettings: [], columns: [] },
  action
) => {
  switch (action.type) {
    case SETTING_LIST_REQUEST:
      return { ...state, loading: true, error: [] };
    case SETTING_LIST_SUCCESS:
      var data = action.payload.data;
      var columns = [
        {
          label: 'image',
          field: 'urlImage',
          width: 270,
        },
        {
          label: 'title',
          field: 'title',
          width: 200,
        },
        {
          label: 'sub title',
          field: 'subtitle',
          width: 100,
        },
        // {
        //   label: 'url',
        //   field: 'url',
        //   // sort: 'asc',
        //   width: 100,
        // },
        {
          label: 'ordering',
          field: 'ordering',
          // sort: 'asc',
          width: 100,
        },
        {
          label: 'status',
          field: 'status',
          sort: 'disabled',
          width: 150,
        },
        {
          label: 'created by',
          field: 'created_by',
          width: 150,
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },
      ];
      // let no = 0;
      for (let i = 0; i < data.length; i++) {
        // no++;
        var btn = (
          <BTNDatatable
            id={data[i].id}
            url={'settings/' + data[i].id}
            label="setting"
          />
        );
        var img = <img src={data[i].img} alt="" height="30px" />;
        // var img = <img src={`http:://api/img/${data[i].img}`} alt="" />;
        data[i].action = btn;
        data[i].urlImage = img;
        // data[i].no = no;
      }
      return {
        ...state,
        loading: false,
        listBanners: data,
        columns: columns,
      };
    case SETTING_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const settingEditReducer = (state = { settings: {} }, action) => {
  switch (action.type) {
    case SETTING_EDIT_REQUEST:
      return { ...state, loading: true, error: [] };
    case SETTING_EDIT_SUCCESS:
      return { ...state, loading: false, settings: action.payload.data };
    case SETTING_EDIT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const settingUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTING_UPDATE_REQUEST:
      // message.loading('Loading...', key);
      return { ...state, loading: true, error: [], validation: [] };
    case SETTING_UPDATE_SUCCESS:
      // message.success({ content: 'Update success', key });
      return { ...state, loading: false };
    case SETTING_UPDATE_VALIDATION:
      // message.error({ content: 'Please fill all required field', key });
      return { ...state, loading: false, validation: action.payload };
    case SETTING_UPDATE_FAIL:
      // message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
