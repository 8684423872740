/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { EffectFade, Autoplay } from 'swiper';

// import 'swiper/css';
// import 'swiper/css/effect-fade';
// loader
import ScaleLoader from 'react-spinners/ScaleLoader';

// get data api
import { useDispatch, useSelector } from 'react-redux';
import { getbannerList } from '../store/action/bannerAction.js';
import { settingEdit } from '../store/action/settingAction.js';
import { getakomodationList } from '../store/action/akomodationAction.js';
import { getpackageList } from '../store/action/packageAction.js';
import { eventShow } from '../store/action/eventAction.js';
import { getcampsiteList } from '../store/action/campsiteAction.js';
import { limitGallery } from '../store/action/galleryAction.js';

// css
// import '../assets/css/app.css';
import '../assets/css/Home.css';
import Img1 from '../assets/img/batu/img1.jpg';
import Img2 from '../assets/img/batu/img2.jpg';

const Themes = props => {
  /**
titile menu
 */
  const [aboutT, setAboutT] = useState('');
  const [aboutST, setAboutST] = useState('');
  const [campsiteT, setCampsiteT] = useState('');
  const [campsiteST, setCampsiteST] = useState('');
  const [reserveT, setReserveT] = useState('');
  const [reserveST, setReserveST] = useState('');
  const [packageT, setPackageT] = useState('');
  const [packageST, setPackageST] = useState('');
  const [galleryT, setGalleryT] = useState('');
  const [galleryST, setGalleryST] = useState('');
  const [aboutbT, setAboutbT] = useState('');
  const [aboutbST, setAboutbST] = useState('');
  const [mapsT, setMapsT] = useState('');
  const [mapsST, setMapsST] = useState('');

  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  // const getSection = () =>
  //   ;

  // set aos animation
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    localStorage.removeItem('akomondationDetail');
    AOS.init();
    // AOS.init({
    //   duration: 2000,
    // });
  }, []);

  let history = useHistory();
  let dispatch = useDispatch();

  function btnAkomondation(slug, id) {
    history.push('akomondation/' + slug);
    localStorage.setItem('akomondationDetail', id);
  }

  function btnPackage(slug, id) {
    history.push('packages/' + slug);
    localStorage.setItem('packageDetail', id);
  }
  function btnCampsites(slug, id) {
    history.push('campsites/' + slug);
    localStorage.setItem('campsiteDetail', id);
  }

  function viewGallery() {
    history.push('gallery/');
  }

  useEffect(() => {
    dispatch(getbannerList('0'));
    dispatch(getcampsiteList('0'));
    dispatch(getakomodationList('0'));
    dispatch(getpackageList('0'));
    dispatch(settingEdit('1'));
    dispatch(eventShow('1'));
    dispatch(limitGallery('10'));
    Axios.get(`sectionmenus`).then(response => {
      setAboutT(response.data.data['0'].title);
      setAboutST(response.data.data['0'].subtitle);
      setCampsiteT(response.data.data['1'].title);
      setCampsiteST(response.data.data['1'].subtitle);
      setReserveT(response.data.data['2'].title);
      setReserveST(response.data.data['2'].subtitle);
      setPackageT(response.data.data['3'].title);
      setPackageST(response.data.data['3'].subtitle);
      setGalleryT(response.data.data['4'].title);
      setGalleryST(response.data.data['4'].subtitle);
      setAboutbT(response.data.data['5'].title);
      setAboutbST(response.data.data['5'].subtitle);
      setMapsT(response.data.data['6'].title);
      setMapsST(response.data.data['6'].subtitle);
    });
  }, [dispatch]);

  const dataBanner = useSelector(state => state.bannerList);
  const { listBanners } = dataBanner;

  const dataEvent = useSelector(state => state.eventShow);
  const { events } = dataEvent;

  const dataAkomodation = useSelector(state => state.akomodationList);
  const { listAkomodations } = dataAkomodation;

  const datacampsite = useSelector(state => state.campsiteList);
  const { listcampsites } = datacampsite;

  const datapackage = useSelector(state => state.packageList);
  const { listPackages } = datapackage;

  const datasettingEdit = useSelector(state => state.settingEdit);
  const { settings } = datasettingEdit;

  const datagalleryEdit = useSelector(state => state.limitGallery);
  const { limitsGallery } = datagalleryEdit;

  /**
click btn scroll smooth 
*/
  const aboutSection = useRef(null);
  const BtnAbout = props => {
    window.scrollTo({
      top: aboutSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const campsiteSection = useRef(null);
  const BtnCampsite = () => {
    window.scrollTo({
      top: campsiteSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const akomondationsSection = useRef(null);
  const BtnAkomondations = () => {
    window.scrollTo({
      top: akomondationsSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const packagesSection = useRef(null);
  const BtnPackages = () => {
    window.scrollTo({
      top: packagesSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const eventSection = useRef(null);
  const BtnEvent = () => {
    window.scrollTo({
      top: eventSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  // set navbar
  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 30
        ? setStickyClass('navbar-active')
        : setStickyClass('relative');
    }
  };

  //btn link send whatsapp
  function bookNow(title = '') {
    window.open(
      'https://api.whatsapp.com/send?phone=' +
        settings.telp +
        '&text=Hallo batu Camp! Saya ingin booking%20',
      '_blank'
    );
  }

  // document.getElementById('nav-icon').addEventListener('click', function () {
  //   document.querySelector('.side-nav-list').classList.toggle('slide-in');
  //   document.querySelector('.hamburger').classList.toggle('close');
  // });

  return (
    <>
      {loading ? (
        <div className="loader-spin">
          <ScaleLoader color={'#03441A'} loading={loading} size={500} />
        </div>
      ) : (
        <>
          <div id="navbar" className={stickyClass}>
            <div className="sec__navbar">
              <div className="container">
                <div className="topnavbar">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col">
                      <div className="topleft">
                        <div className="icall animate-fadeup">
                          <a
                            href={'tel:+' + settings.telp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>
                              <i className="fas fa-phone-volume fa-fw"></i>
                            </span>
                          </a>
                        </div>
                        <div className="title animate-fadeup">
                          <p>For </p>
                          <p>+{settings.telp}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col text-center animate-fadeup">
                      <img src={settings.logo} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4 col text-right m-auto">
                      <div className="topright animate-fadeup">
                        <span className="btnbar pl-4" onClick={showSidebar}>
                          <i className="fas fa-bars fa-fw"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <nav className="nav-bottom">
                  <div className="row wrap__content">
                    <div className="col-lg-2 col-12 order1">
                      <div className="menu__res">
                        <a href={settings.fb} target="_blank" rel="noreferrer">
                          <span className="animate-fadeup">
                            <i className="fab fa-facebook"></i>
                          </span>
                        </a>
                        <a href={settings.ig} target="_blank" rel="noreferrer">
                          <span className="animate-fadeup">
                            <i className="fab fa-instagram"></i>
                          </span>
                        </a>
                        <a href={settings.yt} target="_blank" rel="noreferrer">
                          <span className="animate-fadeup">
                            <i className="fab fa-youtube"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-10 col-12 order2">
                      <div className="wrap__menu just">
                        <ul>
                          <li className="active">
                            <a className="animate-fadeup" href="/site">
                              Home
                            </a>
                          </li>
                          <li>
                            <a
                              className="animate-fadeup"
                              href="##"
                              onClick={BtnAbout}
                            >
                              About Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="animate-fadeup"
                              href="##"
                              onClick={BtnCampsite}
                            >
                              3 Spot Campsite
                            </a>
                          </li>
                          <li>
                            <a
                              className="animate-fadeup"
                              href="##"
                              onClick={BtnAkomondations}
                            >
                              Akomodations
                            </a>
                          </li>
                          <li>
                            <a
                              className="animate-fadeup"
                              href="##"
                              onClick={BtnPackages}
                            >
                              Packages
                            </a>
                          </li>
                          <li>
                            <a
                              className="animate-fadeup"
                              href="##"
                              onClick={BtnEvent}
                            >
                              Event
                            </a>
                          </li>
                        </ul>
                        <div className="wrap__button" id="viewAbout">
                          <button
                            className="btn btn-custom btn-view animate-fadeup"
                            onClick={() => bookNow()}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <nav className={sidebar ? 'side-nav slide-in' : 'side-nav'}>
            <div id="nav-icon" className="hamburger">
              <span className="bar" onClick={showSidebar}></span>
            </div>
            <ul className="side-nav-list">
              <li className="side-nav-item">
                <a className="side-nav-link" href="/site">
                  Home
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="##" onClick={BtnAbout}>
                  About Us
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="##" onClick={BtnCampsite}>
                  3 Spot Campsite
                </a>
              </li>
              <li className="side-nav-item">
                <a
                  className="side-nav-link"
                  href="##"
                  onClick={BtnAkomondations}
                >
                  Akomodations
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="##" onClick={BtnPackages}>
                  Packages
                </a>
              </li>
              <li className="side-nav-item">
                <a className="side-nav-link" href="##" onClick={BtnEvent}>
                  Event
                </a>
              </li>
              <li className="side-nav-item">
                <div className="sosmed">
                  <a href={settings.fb} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-facebook"></i>
                    </span>
                  </a>
                  <a href={settings.ig} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                  <a href={settings.yt} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fab fa-youtube"></i>
                    </span>
                  </a>
                </div>
              </li>
              <li className="side-nav-item">
                <div className="wrap__button">
                  <button
                    className="btn btn-custom btn-view"
                    onClick={() => bookNow()}
                  >
                    Book Now
                  </button>
                </div>
              </li>
            </ul>
          </nav>

          <div className="sec__banner">
            <Swiper
              modules={[EffectFade, Autoplay]}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              effect="fade"
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              // coverflowEffect={{
              //   rotate: 50,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 1,
              //   slideShadows: false,
              // }}
              // pagination={true}
              className="mySwiper"
            >
              {listBanners.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="wrap__content">
                      <img
                        id={i}
                        src={item.img}
                        alt=""
                        className="img-fluid img__content"
                      />
                      <div className="title__content">
                        {item.title ? (
                          <h2 className="animate-fadeup-text">{item.title}</h2>
                        ) : (
                          ''
                        )}
                        {item.titlehigh ? (
                          <h1 className="animate-fadeup-text">
                            {item.titlehigh}
                          </h1>
                        ) : (
                          ''
                        )}
                        {item.subtitle ? (
                          <p className="animate-fadeup-text">{item.subtitle}</p>
                        ) : (
                          ''
                        )}
                        <div className="action__content animate-fadeup-text">
                          <button className="btn btn-custom" onClick={BtnAbout}>
                            Learn More
                          </button>
                          <button
                            className="btn btn-white bgGreen"
                            onClick={() => bookNow()}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="sec__about" id="aboutus" ref={aboutSection}>
            <div className="container">
              <div className="heading">
                <h2>{aboutT}</h2>
                <h6>{aboutST}</h6>
              </div>
              <div className="row" data-aos="fade-up">
                <div className="col-lg-6 col-12 order2 m-auto">
                  <div className="title__content">
                    <p>
                      Curug batu gede cisuren camp merupakan salah satu tempat
                      wisata alam di Puncak bogor yang memiliki pemandangan alam
                      yang luar biasa indah. Berada di kawasan hutan sekunder
                      ketinggian 1000 sampai 1200 meter di atas permukaan laut
                      suhu udara terendah adalah 10 derajat celcius dan
                      rata2-rata adalah 18 derajat celcius, kawasan hutan ini
                      menyipan kekayaan alam yang luar biasa mata air, air
                      terjun, sungai, hutan dan bukit-bukit dengan view indah
                      menghadap langsung kepada gunung gede pangrango dan saat
                      malam bisa menikmati cayaha kota yang dilihat dari
                      ketinggian (city light) yang ada di kawasan cisarua puncak
                      bogor dan sekitarnya.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="img__content">
                    <img src={Img1} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="row mt-5" data-aos="fade-up">
                <div className="col-lg-6 col-12">
                  <div className="img__content">
                    <img src={Img2} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 m-auto">
                  <div className="title__content">
                    <p>
                      Salah satu yang memiliki pemandangan alam terindah adalah
                      curug batu gede air terjun terletak di tengah2 hutan dan
                      jatuhan air melewati batu yang terjun ke bawah dari
                      ketinggian membawa hawa dingin disertai angin yang
                      berhembus menerpa pepohonan didalam hutan lalu bunyi air,
                      suara serangga dan burung suatu kemegahan yang di
                      pertontonkan dari alam bebas curug batu gede cisuren.
                      Pemandangan alam yang memesona tak hanya bagus untuk
                      postingan instagram tapi baik untuk jiwa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="sec__campsite"
            data-aos="fadeInUp"
            ref={campsiteSection}
          >
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{campsiteT}</h2>
                <h6>{campsiteST}</h6>
              </div>
              <div className="row">
                {listcampsites.map((item, i) => {
                  const str = item.title;
                  const new_str = str.replace(/\s+/g, '-');
                  const slug = new_str.toLowerCase();
                  return (
                    <div
                      className="col-lg-4 col-sm-6 col-12"
                      data-aos="fade-up"
                      key={i}
                    >
                      <div
                        className="wrap__content"
                        onClick={() => btnCampsites(slug, item.id)}
                      >
                        <img
                          src={item.img}
                          className="img-fluid bg__content"
                          alt=""
                        />
                        <div className="wrap__card">
                          <div className="shape">
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                            <span>
                              <i className="fas fa-star fa-fw"></i>
                            </span>
                          </div>
                          <h4>{item.title}</h4>
                          <p>{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                ;
              </div>
              <div
                className="wrap__action"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <button className="btn btn-custom" onClick={() => bookNow()}>
                  Book Now
                </button>
              </div>
            </div>
          </div>

          <div className="sec__reserve" ref={akomondationsSection}>
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{reserveT}</h2>
                <h6>{reserveST}</h6>
              </div>
              {listAkomodations.map((item, i) => {
                // const str = item.title;
                // const new_str = str.replace(/\s+/g, '-');
                // const slug = new_str.toLowerCase();
                return (
                  <div className="row wrap__content" key={i} data-aos="fade-up">
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img1}
                        className="img-fluid bg__content img1"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img2}
                        className="img-fluid bg__content"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <div className="title__content">
                        <img
                          src={item.img3}
                          className="img-fluid bg__content img3"
                          alt=""
                        />
                        <div className="title">
                          <h3>{item.title}</h3>
                          <p>{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0 wrap__action">
                      <div className="wrap__card">
                        <h4>{item.harga_biasa}</h4>
                        <h5>/Night</h5>
                        <button
                          className="btn btn-custom"
                          onClick={() => btnAkomondation(item.slug, item.id)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              ;
            </div>
          </div>

          <div className="sec__reserve" ref={packagesSection}>
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{packageT}</h2>
                <h6>{packageST}</h6>
              </div>
              {listPackages.map((item, i) => {
                const str = item.title;
                const new_str = str.replace(/\s+/g, '-');
                const slug = new_str.toLowerCase();
                return (
                  <div className="row wrap__content" key={i} data-aos="fade-up">
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img1}
                        className="img-fluid bg__content img1"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <img
                        src={item.img2}
                        className="img-fluid bg__content"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0">
                      <div className="title__content">
                        <img
                          src={item.img3}
                          className="img-fluid bg__content img3"
                          alt=""
                        />
                        <div className="title">
                          <h3>{item.title}</h3>
                          <p>{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 p-0 wrap__action">
                      <div className="wrap__card">
                        <h4>{item.harga_biasa}</h4>
                        <h5>/Person</h5>
                        <button
                          className="btn btn-custom"
                          onClick={() => btnPackage(slug, item.id)}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              ;
            </div>
          </div>

          <div className="sec__photo">
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{galleryT}</h2>
                <h6>{galleryST}</h6>
              </div>
              <div className="row">
                <div className="col-lg-12" data-aos="fade-up">
                  <ul>
                    {limitsGallery.map((item, i) => {
                      return (
                        <li key={i}>
                          <img src={item.img} alt="a" loading="lazy" />
                        </li>
                      );
                    })}
                    ;
                  </ul>
                  <div
                    className="action__content text-center pt-4"
                    data-aos="fade-up"
                    data-aos-duration="6000"
                  >
                    <button
                      className="btn btn-custom"
                      onClick={() => viewGallery()}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {events ? (
            <div className="sec__event" ref={eventSection}>
              <div className="wrap__content">
                <img
                  src={events.img}
                  alt=""
                  className="img-fluid img__content"
                />
                <div className="title__content" data-aos="fade-up">
                  <img
                    src={settings.logo}
                    className="img-fluid img__logo"
                    alt=""
                  />
                  <h2 data-aos="fade-up">{events.title}</h2>
                  <h1 data-aos="fade-up">{events.subtitle}</h1>
                  <div
                    className="action__content"
                    data-aos="fade-up"
                    data-aos-duration="6000"
                  >
                    <button
                      className="btn btn-custom"
                      onClick={() => bookNow('Saya ingin join event!')}
                    >
                      Join the event !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="sec__yt">
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{aboutbT}</h2>
                <h6>{aboutbST}</h6>
              </div>
              <div className="wrap__content" data-aos="fade-up">
                <iframe
                  className="iframe"
                  src={settings.video}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="sec__map">
            <div className="container">
              <div className="heading" data-aos="fade-up">
                <h2>{mapsT}</h2>
                <h6>{mapsST}</h6>
              </div>
              <div className="wrap__content" data-aos="fade-up">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.7176740981236!2d106.96872979999999!3d-6.681854700000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69b6f2f0b2e953%3A0xb12c39fce4ae6fbc!2sCurug%20batu%20gede%20cisuren!5e0!3m2!1sen!2sid!4v1658130920784!5m2!1sen!2sid"
                  className="iframe"
                  title="map"
                  async
                  defer
                  style={{ border: '20px solid #f4f4f4' }}
                ></iframe>
              </div>
            </div>
          </div>

          <footer>
            <p>
              {settings.footer}
              {/* Copyright © {new Date().getFullYear()} Curug Batu Gede Cisuren. All */}
              {/* right reserved */}
            </p>
          </footer>
        </>
      )}
    </>
  );
};

export default Themes;
